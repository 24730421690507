import React, { useContext } from "react"
import styles from "./comeInList.module.scss"
import { AppContext } from "../../context-manager"

const ComeInList = ({items =[], comeIn = 3}) => {
  const { shopInfo } = useContext(AppContext)

  let target = ""
  switch(comeIn) {
    case 3:
      target = "products"
      break
    case 4:
      target = "collections"
      break
    default:
      throw new Error("invalid come in")
  }
  const tableList = items.map((item, k) => {
    if(item.imageUrl === "") {
      item.imageUrl = undefined
    }
    return (<tr key={item.sourceId.toString()}>
      {item.imageUrl && item.imageUrl !== "" && <td width="35px"><img src={item.imageUrl} alt={item.title} /></td>}
      <td>{!item.imageUrl && <label>{k+1}.</label>}<a href={`https://${shopInfo.domain}/${target}/${item.handle}`} target="_blank" rel="noopener noreferrer">{item.title}</a></td>
    </tr>)
  })

  return (
    items.length > 0 && <div className={styles.selectedBox}>
      <table className={styles.tableList}>
        <tbody>
        {tableList}
        </tbody>
      </table>
    </div>
  )
}

export default ComeInList