import React, { useState, Fragment } from "react"
import { FormLayout, TextField, Select, Button} from '@shopify/polaris';
import styles from "./index.module.scss"
import { withTranslation } from 'react-i18next';

const CartFireCondition = ({conditions = [], onChange, t}) => {
  const [cartType, setCartType] = useState(1)
  const [operator, setOperator] = useState(1)
  const [logic, setLogic] = useState(1)
  const [conditionValue, setConditionValue] = useState("")
  const [carConditions, setCarConditions] = useState(conditions || [])
  // const [canAdd, setCanAdd] = useState(true)

  const handleRemoveOperatorItem = (item) => {
    // console.log(item)
    const tempConditions = [...carConditions]
    const newConditions = tempConditions.filter(cd => !(cd.type === item.type && cd.operator === item.operator && cd.value === item.value && cd.logic === item.logic))
    setCarConditions(newConditions)
    onChange && onChange(newConditions)
  }
  const conditionList = carConditions.map((item,index) => {
    let typeDesc = ""
    switch(parseInt(item.type)) {
      case 1:
        typeDesc = t("cartCondition.cartValue")
        break
      case 2:
        typeDesc = t("cartCondition.cartQuantity")
        break
      default:
        break
    }
    let operatorDesc = ""
    switch(parseInt(item.operator)) {
      case 1:
        operatorDesc = "<="
        break
      case 2:
        operatorDesc = ">="
        break
      default:
        break
    }
    let opt = ""
    switch(parseInt(item.logic)) {
      case 1:
        opt = "AND"
        break
      case 2:
        opt = "OR"
        break
      default:
        break
    }
    if(opt !== "") {
      return <Fragment key={index + 1}><span> {opt} </span><label>{typeDesc} {operatorDesc} {item.value}<span onClick={() => handleRemoveOperatorItem(item)}>&#x2715;</span></label></Fragment>
    } else {
      return <label key={index + 1}>{typeDesc} {operatorDesc} {item.value}<span onClick={() => handleRemoveOperatorItem(item)}>&#x2715;</span></label>
    }
    // return <div key={index + 1} className={styles.item}>{index + 1}. {typeDesc} <label>{operatorDesc}</label> <label>{item.value}</label></div>
    
    
  })
  const typeOptions = [
    {label: t("cartCondition.cartValue"), value: '1'},
  ]
  const operatorOptions = [
    {label: t("cartCondition.lessThan"), value: '1'},
    {label: t("cartCondition.greaterTo"), value: '2'}
  ]

  const logicOptions = [
    {label: 'AND', value: '1'},
    {label: 'OR', value: '2'}
  ]

  const handleTypeSelect = (val) => {
    setCartType(val)
  }
  const handleOperatorSelect = (val) => {
    setOperator(val)
  }
  const handleLogicSelect = (val) => {
    setLogic(val)
  }

  const handleValueChange = (val) => {
    if(/^[0-9\\.]+$/.test(val) || val === "") {
      setConditionValue(val)
    }

  }
  const handleAddBtnClick = () => {
    const tempList = [...carConditions]
    if(tempList.length === 0) {
      tempList.push({type: cartType, operator: operator, value: conditionValue, logic: '0'})
    } else {
      tempList.push({type: cartType, operator: operator, value: conditionValue, logic: logic})
    }
    
    setCarConditions(tempList)
    setConditionValue("")
    onChange && onChange(tempList)
    
  }
  return (
    <div className={styles.cartCondition}>
      <FormLayout.Group>
        {
          carConditions.length > 0 && <Select options={logicOptions} value={logic} onChange={handleLogicSelect} />
        }
        <Select options={typeOptions} value={cartType} onChange={handleTypeSelect} />
        <Select options={operatorOptions} value={operator} onChange={handleOperatorSelect} />
        <TextField value={conditionValue} type="text" placeholder={t("cartCondition.totalPriceHolder")} onChange={handleValueChange} />
        
        <Button onClick={handleAddBtnClick} disabled={conditionValue === ""}>{t("cartCondition.add")}</Button>
      </FormLayout.Group>
      <div className={styles.conditionDisplay}>
      {conditionList}
      </div>
      <FormLayout.Group >
          
      </FormLayout.Group>
    </div>
  )
}

export default withTranslation()(CartFireCondition)
