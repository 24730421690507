import React from "react"
import {Layout, Card, Page, Frame} from '@shopify/polaris';
import ReactPlayer from "react-player";
import styles from './style.module.scss'
const Helper = () => {
  
  return (
    <Frame>
      <Page fullWidth>
        <Layout>
          <Layout.Section>
            <Card title="Guide videos" sectioned>
              <div>
                <div>
                  <div className={styles.title}>1. How to config this APP.</div>
                  <ReactPlayer url="https://youtu.be/m8D4dpSm7H8" />
                </div>
                <div>
                  <div className={styles.title}>2. Base operation.</div>
                  <ReactPlayer url="https://youtu.be/zC-vvA7AXS0" />
                </div>
              </div>
            </Card>
          </Layout.Section>
        </Layout>
      </Page>
    </Frame>
  )
}
export default Helper

