import React from "react"
import styles from './index.module.scss'

const RadioButton = ({onChange, children, checked = false, disabled = false}) => {

  const radioBtnStyle = checked ? `${styles.checkbox} ${styles.ischecked}` : `${styles.checkbox}`

  return (<div onClick={onChange} className={styles.box}>
    <input type="radio" defaultChecked={checked} className={radioBtnStyle} disabled={disabled} />
    {children && <span>{children}</span>}
    </div>
  )
}

export default RadioButton