import React, {useState, useEffect, useContext} from "react"
import {Page, Card, EmptyState, DataTable, ButtonGroup, Button, Layout, Banner, Modal} from '@shopify/polaris';
import { CommonPageState } from "../../utils/common_state"
import CouponPreview from "./couponPreview"
import SurveyPreview from "../survey/surveyPreview";
import RecommendProductView from "../upsell/recommend_product_view";
import SubscribePreview from "../subscriber/preview";
import ComeInList from "./comeInList";
import styles from './list.module.scss'
import { AppContext } from "../../context-manager";
import { deleteAction, activeAction, disableAction,getActionList } from '../../api/action'
import { upgradeToBasicPlan } from "../../api/charge";

import ReactEChartsCore from 'echarts-for-react/lib/core';

import * as echarts from 'echarts/core';
import { PieChart } from 'echarts/charts';
import { TooltipComponent, TitleComponent, LegendComponent} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { withTranslation, Trans } from 'react-i18next';



echarts.use(
  [TitleComponent, TooltipComponent, PieChart, LegendComponent, CanvasRenderer]
);

const ActionList = ({sendChgState, defaultPressedBtn, t}) => {
  // console.log(defaultPressedBtn)

  const { shopInfo } = useContext(AppContext)
  const [unActiveItems, setUnActiveItems] = useState([])
  // const [rows, setRows] = useState([])
  const [activeItems, setactiveItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [showReminder, setShowReminder] = useState(true)
  const [showUpgrade, setShowUpgrade] = useState(false)
  const [showUpgradeButton, setShowUpgradeButton] = useState(true)
    
  const initList = () => {
    getActionList(1).then( async (dt) => {
      for(let item of dt.items) {
        item.showPreview = true
        item.showStatistics = true
      }
      await setactiveItems(dt.items || [])
      getActionList(0).then( async (unactiveData) => {
        await setUnActiveItems(unactiveData.items || [])
        await setLoading(false)

      })
    })
  }

  useEffect(initList, [shopInfo])


  let isLPInitVal, isCOLSInitVal, isCARTInitVal, isHomeInitVal, isThankYouInitVal  = false 

  if(defaultPressedBtn !== undefined) {
    const selVal = parseInt(defaultPressedBtn)
    switch(selVal) {
      case 0:
      case 3:
        isLPInitVal = true 
        break
      case 1:
      case 4:
        isCOLSInitVal = true 
        break
      case 2:
        isCARTInitVal = true 
        break
      case 5:
        isHomeInitVal = true
        break
      case 6:
        isThankYouInitVal = true
        break
      default:
        isHomeInitVal = true
    }
  } else {
    isHomeInitVal = true 
  }

  const [isLPButtonActive, setLPButtonActive] = useState(isLPInitVal)
  const [isCOLSButtonActive, setCOLSButtonActive] = useState(isCOLSInitVal)
  const [isCARTButtonActive, setCARTButtonActive] = useState(isCARTInitVal)
  const [isHomeButtonActive, setHomeButtonActive ] = useState(isHomeInitVal)
  const [isThankYouButtonActive, setThankYouButtonActive] = useState(isThankYouInitVal)

  const handleLPButtonClick =  () => {
     setLPButtonActive(true)
     setCOLSButtonActive(false)
     setCARTButtonActive(false)
     setHomeButtonActive(false)
     setThankYouButtonActive(false)

  }

  const handleCOLSButtonClick =  () => {
     setLPButtonActive(false)
     setCOLSButtonActive(true)
     setCARTButtonActive(false)
     setHomeButtonActive(false)
     setThankYouButtonActive(false)

  }
  const handleCARTButtonClick =  () => {
     setLPButtonActive(false)
     setCOLSButtonActive(false)
     setCARTButtonActive(true)
     setHomeButtonActive(false)
     setThankYouButtonActive(false)

  }
  const handleHomeButtonClick =  () => {
    setLPButtonActive(false)
    setCOLSButtonActive(false)
    setCARTButtonActive(false)
    setHomeButtonActive(true)
    setThankYouButtonActive(false)

  }
  const handleThankYouButtonClick =  () => {
    setLPButtonActive(false)
    setCOLSButtonActive(false)
    setCARTButtonActive(false)
    setHomeButtonActive(false)
    setThankYouButtonActive(true)

  }

  const removeReminder = () => {
    setShowReminder(false)
  }
  const emptyList =  <>
    {showReminder && <Banner
      title={t("list.bannerTitle")}
      status="info" onDismiss={() => {removeReminder()}}>
      <p>{t("list.bannerReminder")}</p>
    </Banner>}
    <EmptyState
    heading={t("list.emptyHeading")}
    action={{content: t("list.emptyAction"), onAction: () => sendChgState(CommonPageState.CreateItem)}}
    image="https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg">
    </EmptyState>
  </>

  const emptyActiveAction = <div className={styles.emtpyActiveAction}>
    <p>{t("list.emptyActiveAction")}</p>
  </div>

  const getComeInSomeWhereDesc = (comeIn) => {
    switch(comeIn) {
      case 0:
        return <label>{t("list.comeIn.anyProductPage")}</label>
      case 1:
        return <label>{t("list.comeIn.anyCollectionPage")}</label>
      case 2:
        return <label>{t("list.comeIn.shoppingCart")}</label>
      case 3:
        return <label>{t("list.comeIn.specialProductDetailPage")}</label>
      case 4:
        return <label>{t("list.comeIn.specialCollectionPage")}</label>
      case 5:
        return <label>{t("list.comeIn.homePage")}</label>
      case 6:
        return <label>{t("list.comeIn.paySuccessedPage")}</label>
      default:
        return ""
    }
  }
  const getDoActionDesc = (fireMode, seconds) => {
    // console.log(fireMode, seconds)
    switch(fireMode) {
      case "LEAVE":
        return <label>{t("list.doAction.leavePage")}</label>
      case "STAY_IN_SECONDS":
        return <label><Trans i18nKey="list.doAction.staySeconds">When user stay {{seconds}} seconds</Trans></label>
      case "SCROLL_TO_BOTTOM":
        return <label>{t("list.doAction.scrollToBottom")}</label>
      case "CLICK_ADD_TO_CART":
        return <label>{t("list.doAction.clickAddToCart")}</label>
      case "DO_NOTHING":
        return <label>{t("list.doAction.doNothing")}</label>
      default:
        return ""
    }
  }
  const getFireActionDesc = (doAction) => {
    switch(doAction) {
      case 0:
        return <label>{t("list.fireAction.popUpCoupon")}</label>
      case 1:
        return <label>{t("list.fireAction.popUpSurvey")}</label>
      case 2:
        return <label>{t("list.fireAction.recommendProducts")}</label>
      case 3:
        return <label>{t("list.fireAction.popUpSubscribe")}</label>
      default:
        return ""
    }
  }
  const showInDeviceDesc = (showIn) => {
    const smap = {"0": t("list.device.all"), "1": t("list.device.mobile"), "2": t("list.device.pc")}
    return <label>{smap[showIn.toString()]}</label>
  }

  const showDisplayMethodDesc = (displayMethod) => {
    const smap = {"0": t("upsell.edit.displayPopUpOpt"), "1": t("upsell.edit.displayBelowATCBtnOpt"), "2": t("upsell.edit.displayInlineThankYouPageOpt")}
    return <label>{smap[displayMethod.toString()]}</label>
  }

  const getSubscribersDesc = (item) => {
    if(item.hasCustomerLeftEmail) {
      return <a href={`https://${shopInfo.domain}/admin/customers?segment_query=customer_tags%20CONTAINS%20%27Action-${item.name}%27%20`} target="_blank" rel="noopener noreferrer">View Subscribers (by getting coupons)</a>
    } else {
      return <div>{t("list.noneSubscribers")}</div>
    }
  }


  const getSubscribersFromSubscribeWindowDesc = (item) => {
    if(item.hasCustomerLeftEmail) {
      return <a href={`https://${shopInfo.domain}/admin/customers?segment_query=customer_tags%20CONTAINS%20%27Action-${item.name}%27%20`} target="_blank" rel="noopener noreferrer">View All Subscribers</a>
    } else {
      return <div>{t("list.noneSubscribers")}</div>
    }
  }

  const editAction = (item) => {
    sendChgState(CommonPageState.EditItem, {...item})
  }
  const showPreviewFun = (item) => {
    // console.log(item.name)
    const copyItems = [...activeItems]
    for(let val of copyItems) {
      if(val.name === item.name) {
        val.showPreview = !val.showPreview
        break
      }
    }
    setactiveItems(copyItems)
  }

  const showStatisticsFun = (item) => {
    // console.log(item.name)
    const copyItems = [...activeItems]
    for(let val of copyItems) {
      if(val.name === item.name) {
        val.showStatistics = !val.showStatistics
        break
      }
    }
    setactiveItems(copyItems)
  }

  const deleteItem = (item) => {
    if(window.confirm(t("list.alert.areYouDelete"))) {
      deleteAction(item.id).then(dt => {
        initList()
      })
    }
  }

  const activeOrDisableItem = async (item) => {
    const confirmTxt = item.status === 0 ? t("list.alert.areYouActive") : t("list.alert.areYouDisable")
    if(!window.confirm(confirmTxt)) {
      return
    }
    let res = {}
    if(item.status === 0) {
      res = await activeAction(item.id)
    } else {
      res = await disableAction(item.id)
    }
    if(res.isOk) {
      initList()
    }
  }

  const unActiveItemsFilter = (item) => {
    if(isLPButtonActive) {
      return parseInt(item.comeIn) === 0 || parseInt(item.comeIn) === 3
    }
    if(isCOLSButtonActive) {
      return parseInt(item.comeIn) === 1 || parseInt(item.comeIn) === 4
    }
    if(isCARTButtonActive) {
      return parseInt(item.comeIn) === 2
    }
    if(isHomeButtonActive) {
      return parseInt(item.comeIn) === 5
    }
    if(isThankYouButtonActive) {
      return parseInt(item.comeIn) === 6
    }
  }
  const buildTableData = () => {
    let rs = []
    let tempRows = [...unActiveItems]
    tempRows = tempRows.filter(unActiveItemsFilter)
    for(let item of tempRows) {
      item.showPreview = true
      item.showStatistics = false 
      rs.push([item.name, 
        <div className={styles.unactiveListActionFlow}>{getComeInSomeWhereDesc(item.comeIn)} <span className={styles.arrow}>&#8594;</span> {getDoActionDesc(item.fireMode,item.staySeconds)} <span className={styles.arrow}>&#8594;</span> {getFireActionDesc(item.doAction)}</div>,
        item.coupon && (item.coupon.type === 0 ? `${item.coupon.discountValue}% OFF` : `-${item.coupon.currencySymbol}${item.coupon.discountValue} ${t("list.buildTable.discount")}`),
        item.createTime,
        <ButtonGroup segmented>
          {shopInfo && parseInt(shopInfo.plan) > 0 && <Button size="slim" destructive onClick={() => {deleteItem(item)}}>{t("list.buildTable.delete")}</Button> }
          <Button size="slim" onClick={() => {activeOrDisableItem(item)}}>{t("list.buildTable.active")}</Button>
          <Button size="slim" onClick={() => {editAction(item)}}>{t("list.buildTable.edit")}</Button>
        </ButtonGroup>
      ])
    }

    return rs
  }


  const tableList = <DataTable
    columnContentTypes={[
      'text',
      'text',
      'text',
      'text',
      'text',
      '',
    ]}
    headings={[
      t("list.tableList.name"),
      t("list.tableList.userActionFlow"),
      '',
      t("list.tableList.createTime"),
      '',
    ]}
    rows={buildTableData()}
  />



  const statisticsCouponUI = (st) => {
    return (<table className={styles.stTable}>
    <tbody>
      <tr>
        <td>
          {t("list.stCouponUi.trigger")}
          <p>{st && st.fireActionCount}<label> {t("list.stCouponUi.sessions")}</label></p>
        </td>
        <td className={styles.stTd}></td>
      </tr>
      <tr>
        <td>
        {t("list.stCouponUi.gotCoupon")}
          <p>{st && st.gotCouponCount}<label> {t("list.stCouponUi.sessions")}</label></p>
        </td>
        <td className={styles.stTd}>{st && st.gotRate === "" ? "0.00" : st.gotRate}%</td>
      </tr>
      <tr>
        <td>
        {t("list.stCouponUi.closeCoupon")}
          <p>{st && st.closeActionCount}<label> {t("list.stCouponUi.sessions")}</label></p>
        </td>
        <td className={styles.stTd}>{st && st.closeRate === "" ? "0.00" : st.closeRate}%</td>
      </tr>
      <tr>
        <td>
        {t("list.stCouponUi.placeOrder")}
          <p>{st && st.couponApplyToOrderCount}<label> {t("list.stCouponUi.sessions")}</label></p>
        </td>
        <td className={styles.stTd}>{st && st.useRate === "" ? "0.00" : st.useRate}%</td>
      </tr>
      <tr>
        <td>{t("list.stCouponUi.totalAmountOfOrders")}</td><td className={styles.stTd}>${st && st.couponApplyToOrderAmount} <label></label></td>
      </tr>
    </tbody>
  </table>)
  }

  const statisticsSurveyUI = (st) => {
    if(st.fixedGroupOptions) {
      const total = st.fixedGroupOptions.reduce((prev, current) => prev + current.answerCount, 0)
      if(total > 0) {
        for(let i = 0; i < st.fixedGroupOptions.length; i++) {
          const percentVal = st.fixedGroupOptions[i].answerCount === total ? 100 : ((st.fixedGroupOptions[i].answerCount/total)*100).toFixed(0)
          st.fixedGroupOptions[i].rate = percentVal
        }
      }
    }
    const serialData = st.fixedGroupOptions && st.fixedGroupOptions.map(item => {
      const category = item.optionName.replace(".","")
      if(item.rate) {
        return {name: `${category} (${item.rate}%)`, value: item.answerCount}
      } else {
        return {name: category, value: item.answerCount}
      }
    })

    const pieOptions = {
      tooltip : {
        trigger: 'item',
      },
      legend: {
        orient: 'vertical',
        left: 'left',
        fontSize: 14
        // data: legendData
      },
      series : [
      {
        name: 'Option',
        type: 'pie',
        radius : '50%',
        // center: ['50%', '60%'],
        data: serialData,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          }
        }
      }
      ]
    }
    const otherContentsList = st.otherContents && st.otherContents.map((item,index) => {
      return <tr key={index+1}><td>{index+1}. {item.content}</td><td>{item.date}</td></tr>
    })
    const otherAnswerBoxStyle = {
      marginTop: "10px"
    }
    return (<table className={styles.stTable}>
    <tbody>
      <tr>
        <td>
        {t("list.stSurveyUi.trigger")}
          <p>{st && st.fireCount}<label> {t("list.stSurveyUi.sessions")}</label></p>
        </td>
        <td className={styles.stTd}></td>
      </tr>
      <tr>
        <td>
        {t("list.stSurveyUi.submitSurvey")}
          <p>{st && st.submitCount}<label> {t("list.stSurveyUi.sessions")}</label></p>
        </td>
        <td className={styles.stTd}>{st && st.submitRate === "" ? "0.00" : st.submitRate}%</td>
      </tr>
      <tr>
        <td>
        {t("list.stSurveyUi.closeSurvey")}
          <p>{st && st.closeCount}<label> {t("list.stSurveyUi.sessions")}</label></p>
        </td>
        <td className={styles.stTd}>{st && st.closeRate === "" ? "0.00" : st.closeRate}%</td>
      </tr>
      <tr>
        <td colSpan="2">
          <div>{t("list.stSurveyUi.optionAnswers")}</div>
          {st.fixedGroupOptions && <div className={styles.pieContainer}>
            <ReactEChartsCore
              echarts={echarts}
              option={pieOptions}
              style={{ height: 400 }}
            />
          </div>}
        </td>
      </tr>
      <tr>
        <td colSpan="2">
          <div style={otherAnswerBoxStyle}>{t("list.stSurveyUi.otherAnswers")}</div>
          <table className={styles.innerTable}><tbody>{otherContentsList}</tbody></table>
        </td>
      </tr>
    </tbody>
  </table>)
  }

  const statisticsSubscribeUI = (st) => {
    return (<table className={styles.stTable}>
    <tbody>
      <tr>
        <td>
          {t("list.stSubscribeUi.trigger")}
          <p>{st && st.fireCount}<label> {t("list.stSubscribeUi.sessions")}</label></p>
        </td>
        <td className={styles.stTd}></td>
      </tr>
      <tr>
        <td>
        {t("list.stSubscribeUi.gotSubscribe")}
          <p>{st && st.submitCount}<label> {t("list.stSubscribeUi.sessions")}</label></p>
        </td>
        <td className={styles.stTd}>{st && (st.submitRate === "" ? "0.00" : st.submitRate)}%</td>
      </tr>
      <tr>
        <td>
        {t("list.stSubscribeUi.closeSubscribe")}
          <p>{st && st.closeCount}<label> {t("list.stSubscribeUi.sessions")}</label></p>
        </td>
        <td className={styles.stTd}>{st && (st.closeRate === "" ? "0.00" : st.closeRate)}%</td>
      </tr>
    </tbody>
  </table>)
  }

  const statisticsRecommendProductsUI = (st) => {
    return (<table className={styles.upsellTable}>
    <tbody>
      <tr>
        <td>
        {t("list.stRecommendPtUi.trigger")}
          <p>{st && st.fireCount}<label> {t("list.stRecommendPtUi.sessions")}</label></p>
        </td>
        <td className={styles.stTd}></td>
      </tr>
      <tr>
        <td>
        {t("list.stRecommendPtUi.addToCart")}
          <p>{st && st.addCount} ({st && st.addRate === "" ? "0.00" : st.addRate}%)<label> {t("list.stRecommendPtUi.sessions")}</label></p>
        </td>
        <td className={styles.stTd}></td>
      </tr>
      <tr className={styles.upsellSt}>
        <ul>
          {
            st.items && st.items.map((item,index) => {
              let title = item.title
              if(title.length > 80) {
                title = `${title.substring(0,80)}...`
              }
              return <li key={index + 1}>
                <span><img src={item.imageUrl} alt={item.title} /></span>
                <span><a href={item.link} target="_blank" rel="noopener noreferrer" title={item.title}>{title} ({item.variantTitle})</a></span>
                <span>{t("list.stRecommendPtUi.add")} (<label>{item.count}</label>) {t("list.stRecommendPtUi.times")}</span>
              </li>
            })
          }
        </ul>
      </tr>
    </tbody>
  </table>)
  }

  const hideActionDesc = (item) => {
    switch(parseInt(item.doAction)) {
      case 0:
        return t("list.hideAction.hideCoupon")
      case 1:
        return t("list.hideAction.hideSurvey")
      case 2:
        return t("list.hideAction.hideRecommendPt")
      default:
        return ""
    }
  }
  const previewActionDesc = (item) => {
    switch(parseInt(item.doAction)) {
      case 0:
        return t("list.previewAction.previewCoupon")
      case 1:
        return t("list.previewAction.previewSurvey")
      case 2:
        return t("list.previewAction.previewRecommendPt")
      case 3:
        return t("list.previewAction.previewSubscribe")
      default:
        return ""
    }
  }

  const getItemsByFrom = (comeSomeWhereChkFun) => {

    const itemsByFrom = activeItems.filter(item => comeSomeWhereChkFun(item))
    const list = itemsByFrom.map((item, index) => 
      <Layout.Section  key={item.name}>
      <Card   
          title= <div className={styles.actionHeading}><span>{index+1}</span>{item.name}</div>
          actions={[{content: t("list.itemForm.editAction"), onAction: () => {editAction(item)}}]}
          secondaryFooterActions={[
            {content: item.showPreview ? hideActionDesc(item) : previewActionDesc(item), onAction: () => { showPreviewFun(item) } },
            {content: item.showStatistics ? t("list.itemForm.hideStatistic") : t("list.itemForm.showStatistic"), onAction: () => { showStatisticsFun(item) } },
          ]}
          primaryFooterAction={
            {content: item.status === 0 ? t("list.itemForm.active") : t("list.itemForm.disable"), destructive: item.status !== 0, onAction: () => { activeOrDisableItem(item) }}
          }
      >
        <Card.Section>
        <div className={styles.listAction}>
          <table className={styles.actionInfo}>
            <tbody>
            <tr>
              <td>{t("list.itemForm.userActionFlow")}</td>
              <td className={styles.actionFlowShow}>{getComeInSomeWhereDesc(item.comeIn)} <span className={styles.arrow}>&#8594;</span> {getDoActionDesc(item.fireMode,item.staySeconds)} <span className={styles.arrow}>&#8594;</span> {getFireActionDesc(item.doAction)}</td>
            </tr>
            { item.comeInItems && item.comeInItems.length > 0 &&
            <tr>
              <td></td>
              <td><ComeInList items={item.comeInItems} comeIn={item.comeIn} /></td>
            </tr>
            }
            {
              item.doAction === 0 && <tr>
                <td className={styles.discountTitle}>{t("list.itemForm.couponDiscount")}</td>
                <td className={styles.discountVal}>{item.coupon.type === 0 ? `${item.coupon.discountValue}% OFF` : `-${item.coupon.currencySymbol}${item.coupon.discountValue}` }</td>
              </tr>
            }
            {
              item.survey && item.survey.canGetCouponIfDone && <tr>
                <td>Can get coupon after complete survey</td>
                <td>Coupon code: <strong>{item.survey.couponCode}</strong></td>
              </tr>
            }
            <tr>
              <td>{t("list.itemForm.status")}</td>
              <td>{item.status === 0 ? <span className={styles.unactive}>{t("list.itemForm.unActive")}</span> : <span className={styles.active}>{t("list.itemForm.online")}</span>}</td>
            </tr>
            {
              item.doAction === 2 && <tr>
                <td>{t("list.itemForm.displayMethod")}</td>
                <td>{showDisplayMethodDesc(item.upsell.displayMethod)}</td>
              </tr>
            }
            {
              item.enableURLRestrict && <tr>
                <td>{t("list.itemForm.enableURLRestrict")}</td>
                <td>{t("list.itemForm.urlPatternDesc") + " : " + item.urlPattern}</td>
              </tr>
            }
            <tr>
              <td>{t("list.itemForm.triggerOn")}</td>
              <td>{showInDeviceDesc(item.showInDevice)}</td>
            </tr>
            {
              item.coupon && item.coupon.needLeftEmail && <tr><td>{t("list.itemForm.needLeftEmail")}</td><td>{getSubscribersDesc(item)}</td></tr>
            }
            {
              parseInt(item.doAction) === 3 && <tr><td>{t("list.itemForm.subscribers")}</td><td>{getSubscribersFromSubscribeWindowDesc(item)}</td></tr>
            }
            </tbody>
          </table>
          <div className={styles.create}><label>{t("list.itemForm.createTime")}</label> {item.createTime}</div>
        </div>
        </Card.Section>
        {
          item.doAction === 0 && item.showPreview && <Card.Section title={t("list.itemForm.couponPreview")}>
            <CouponPreview data={item.coupon} />
          </Card.Section>
        }
        {
          item.doAction === 1 && item.showPreview && <Card.Section title={t("list.itemForm.surveyPreview")}>
            <SurveyPreview survey={item.survey} />
          </Card.Section>         
        }
        {
          item.doAction === 2 && item.showPreview && <Card.Section title={t("list.itemForm.recommendPtPreview")}>
            <RecommendProductView products={item.recommendProducts} upsellInfo={item.upsell} />
          </Card.Section>   
        }
        {
          item.doAction === 3 && item.showPreview && <Card.Section title={t("list.itemForm.subscriptionPreview")}>
            <SubscribePreview  subscriber={item.subscriber} />
          </Card.Section>   
        }
        {
          item.showStatistics && <Card.Section title={t("list.itemForm.statistics")}>
            { item.doAction === 0 && statisticsCouponUI(item.couponStatistic) }
            { item.doAction === 1 && statisticsSurveyUI(item.surveyStatistic) }
            { item.doAction === 2 && statisticsRecommendProductsUI(item.upsellStatistic)}
            { item.doAction === 3 && statisticsSubscribeUI(item.subscribeStatistic)}
          </Card.Section>
        }

      </Card>
      </Layout.Section>
    )
    return list.length > 0 ? <Layout>{list}</Layout> : emptyActiveAction
  } 

  const activeLeaveLPItemList = getItemsByFrom((item) => item.comeIn === 0 || item.comeIn === 3)
  const activeLeaveColsItemList = getItemsByFrom((item) => item.comeIn === 1 || item.comeIn === 4)
  const activeLeaveCartItemList = getItemsByFrom((item) => item.comeIn === 2)
  const activeHomeItemList = getItemsByFrom((item) => item.comeIn === 5)
  const activeThankYouItemList = getItemsByFrom(item => item.comeIn === 6)

  const handleUpgradeClick = async () => {
    setShowUpgradeButton(false)
    const returnData = await upgradeToBasicPlan()
    if(returnData && returnData.state === 2) {
      window.top.location.href = returnData.message
    } else {
      setShowUpgrade(false)
      setShowUpgradeButton(true)
      console.log(returnData)
    }

  }
  const handleChangePlanClick = () => {
    setShowUpgrade(true)
  }
  const handleUpgradeCloseClick = () => {
    setShowUpgrade(false)
  }

  const handleClickToComments = () => {
    window.top.location.href = 'https://apps.shopify.com/when-the-user-leaves'
  }
  const upgradeReminder = () => {
    const maxActions = shopInfo.freeMaxActions
    return shopInfo && parseInt(shopInfo.plan) === 0 && <div className={styles.upgrade}>
      <h5>{t("list.freeplan.title")}</h5>
      <ul>
        <li><Trans i18nKey="list.freeplan.actionLimit">You can only add up to {{maxActions}} actions</Trans></li>
        <li>{t("list.freeplan.feature1")}</li>
        <li>{t("list.freeplan.feature2")}</li>
        <li>{t("list.freeplan.feature3")}</li>
      </ul>
      <br />
      <div>{t("leaveComments.content")} <Button plain onClick={handleClickToComments}>{t("leaveComments.clickHere")}</Button></div>
      <div className={styles.opt}><span className={styles.button} onClick={handleChangePlanClick}>{t("list.freeplan.changePlan")}</span></div>
    </div>
  }

  return (
    <>
      
      {!loading && ((unActiveItems.length === 0 && activeItems.length === 0) ? emptyList :     
      (<Page fullWidth title={t("list.rt.actions")} primaryAction={{content: t("list.rt.addAction"), disabled: false, onAction: () => sendChgState(CommonPageState.CreateItem)}}>
        
        { upgradeReminder() }
        <div className={styles.btngroup}>
        <ButtonGroup segmented >
          <Button pressed={isHomeButtonActive} onClick={handleHomeButtonClick}>{t("list.rt.homePage")}</Button>
          <Button pressed={isLPButtonActive} onClick={handleLPButtonClick}>{t("list.rt.productDetailPage")}</Button>
          <Button pressed={isCOLSButtonActive} onClick={handleCOLSButtonClick}>{t("list.rt.collectionPage")}</Button>
          <Button pressed={isCARTButtonActive} onClick={handleCARTButtonClick}>{t("list.rt.shoppingCartPage")}</Button>
          <Button pressed={isThankYouButtonActive} onClick={handleThankYouButtonClick}>{t("list.rt.paySuccessedPage")}</Button>
        </ButtonGroup>
        </div>
        {/* {activeLeaveLPItemList.length === 0 && activeLeaveColsItemList.length === 0 && activeLeaveCartItemList.length === 0 && emptyActiveAction} */}

        {isLPButtonActive && (<>{activeLeaveLPItemList}<div className={styles.tblist}></div></>)}
        {isCOLSButtonActive && (<>{activeLeaveColsItemList}<div className={styles.tblist}></div></>)}
        {isCARTButtonActive && (<>{activeLeaveCartItemList}<div className={styles.tblist}></div></>)}
        {isHomeButtonActive && (<>{activeHomeItemList}<div className={styles.tblist}></div></>)}
        {isThankYouButtonActive && (<>{activeThankYouItemList}<div className={styles.tblist}></div></>)}
        
        <Card  title={t("list.rt.unactiveActionList")}>
          {tableList}
        </Card>
    </Page>))}

    <Modal
        open={showUpgrade}
        onClose={handleUpgradeCloseClick}
        title={t("list.basicPlan.changePlan")}
        >
      <Modal.Section>
        <div className={styles.planList}>
          <h4>{t("list.basicPlan.basicPlan")}</h4>
          <h3>{t("list.basicPlan.eachMonthFee")}</h3>
          <ul>
            <li>{t("list.basicPlan.sevenDaysFree")}</li>
            <li>{t("list.basicPlan.feature1")}</li>
            <li>{t("list.basicPlan.feature2")}</li>
            <li>{t("list.basicPlan.feature3")}</li>
            <li>{t("list.basicPlan.feature4")}</li>
            <li>{t("list.basicPlan.feature5")}</li>
          </ul>
          <div className={styles.opt}>
            {showUpgradeButton && <Button primary size="medium" onClick={handleUpgradeClick}>{t("list.basicPlan.upgrade")}</Button>}
            {(!showUpgradeButton) && <Button primary size="medium">{t("list.basicPlan.upgrading")}</Button>}
          </div>
        </div>
      </Modal.Section>
    </Modal>

    </>
  )
}
export default withTranslation()(ActionList)
