import React from "react"
import styles from "./couponPreview.module.scss"
import SubscribeEmail from "../subscribeEmail"
class CouponPreview extends React.Component {
  constructor() {
    super()
    this.state = {}
  }

  componentDidMount() {

  }
  render() {
    const {discountValue, 
      title, 
      subTitle, 
      gotButtonText,
      bgColor,
      txtColor,
      btnBgColor,
      btnTextColor,
      applyRemindText,
      type,
      currencySymbol,
      needLeftEmail,
      needEmailRemindText,
      bgImage
    } = this.props.data 


    let ctBgStyle = {
      backgroundColor: bgColor,
      color: txtColor
    }

    let cardImageStyle = {}
    if(bgImage && bgImage !== "") {
      cardImageStyle = {...cardImageStyle, 
        backgroundImage: `url("${bgImage}")`,
        backgroundPosition: '50% 50%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        minHeight: "280px",
      }
    }

    const buttonStyle = {
      color: btnTextColor ? btnTextColor : '#fff',
      backgroundColor: `${btnBgColor}`,
    }
    
    const promot = {
      color: `${txtColor}`,
    }

    const closeStyle = {
      color: `${txtColor}`,
    }

    const containerClass = `${styles.container}`

    return (
      <div className={containerClass} style={ctBgStyle}>
        <div className={styles.cardItem}>
          <div className={styles.title}>{title}</div>
          <div className={styles.discount}>
            {(type === 0 || type === '0') ? <div>{discountValue}% OFF</div> : <div>- {currencySymbol}{discountValue}</div>}
          </div>
          {subTitle && subTitle !== "" && <div className={styles.sub_title}>{subTitle}</div>}

          {
            needLeftEmail && <div className={styles.subscribe}><SubscribeEmail disabled={true} placeholder={needEmailRemindText}/></div>
          }
          <div className={styles.operationArea}>
            <button style={buttonStyle}>{gotButtonText}</button>
          </div>
          <div className={styles.prompt} style={promot}>
            {applyRemindText}
          </div>
        </div>
        {(bgImage && bgImage !== "") && <div className={styles.cardItem} style={cardImageStyle}>
        </div>}
        <div className={styles.close} style={closeStyle}>&#x2715;</div>
      </div>
    )
  }

}


export default CouponPreview