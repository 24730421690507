import React from "react"
import styles from './preview.module.scss'
import SubscribeEmail from "../subscribeEmail"

const SubscribePreview = ({subscriber = {}}) => {

  let boxStyle = {
    backgroundColor: subscriber.bkColor,
    color: subscriber.textColor
  }

  // if(subscriber.bgImage && subscriber.bgImage !== "") {
  //   boxStyle = {...boxStyle, 
  //     backgroundImage: `url("${subscriber.bgImage}")`,
  //     backgroundPosition: '50% 0',
  //     backgroundRepeat: 'no-repeat',
  //     backgroundSize: 'cover'
  //   }
  // }
  let cardImageStyle = {}
  if(subscriber.bgImage && subscriber.bgImage !== "") {
    cardImageStyle = {...cardImageStyle, 
      backgroundImage: `url("${subscriber.bgImage}")`,
      backgroundPosition: '50% 50%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      minHeight: "280px",
    }
  }

  const buttonStyle = {
    color: `${subscriber.btnTextColor}`,
    backgroundColor: `${subscriber.btnBkColor}`,
  }

  const closeStyle = {
    color: `${subscriber.textColor}`,
  }

  return (
    <div className={styles.subscribebox} style={boxStyle}>
      <div className={styles.cardItem}>
      <div className={styles.title}>{subscriber.title}</div>
      {subscriber.subTitle && subscriber.subTitle !== "" && <div className={styles.subTitle}>{subscriber.subTitle}</div>}
      <div className={styles.subscribe}>
        <SubscribeEmail disabled={true} placeholder={subscriber.placeHolderText}/>
      </div>
      <div className={styles.unsubscribe}>
        {subscriber.unSubDesc}
      </div>
      <div className={styles.submit}><button style={buttonStyle}>{subscriber.btnText}</button></div>
      </div>
      {(subscriber.bgImage && subscriber.bgImage !== "") && <div className={styles.cardItem} style={cardImageStyle}>
        </div>}
      <div className={styles.close} style={closeStyle}>&#x2715;</div>
    </div>
  )
}

export default SubscribePreview