import React from 'react';
import {AppProvider} from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';
import Privacy from './components/privacy';
import Home from './components/home';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Link
} from "react-router-dom";

const Welcome = () => {

  return (
    <AppProvider i18n={enTranslations}>
      {/* <Page>
        <Card sectioned title="">
        </Card>
      </Page> */}
      <Router>
        <Switch>
           <Route exact path="/">
            <Home />
          </Route>
          {/*<Route path="/about">
            <About />
          </Route> */}
          <Route path="/privacy">
            <Privacy language="en" />
          </Route>
          <Route path="/jp/privacy" >
            <Privacy language="jp" />
          </Route>
        </Switch>
      </Router>

    </AppProvider>
  )
}

export default Welcome