import React from 'react';
import { Page, Button } from '@shopify/polaris';
import styles from './index.module.scss';

const Navigate = () => {
  const installAddress = "https://apps.shopify.com/when-the-user-leaves"

  const handleInstallClick = () => {
    window.location.href = installAddress
  }
  return <Page><div className={styles.home}>
    <div className={styles.title}>
      <a href={installAddress} target="_blank" rel="noopener noreferrer">
      <img src="https://cdn.shopify.com/app-store/listing_images/d39cf7b421c140a6faae7bd43540a431/icon/CN7J47+Fv/QCEAE=.png?height=120&width=120" alt="logo" />
      </a>
      <div className={styles.opt}>
        <h2>PopUp - All in one</h2>
        <Button size="large" primary onClick={handleInstallClick}>Install</Button>
      </div>
    </div>
  </div>
  </Page>
}

export default Navigate
