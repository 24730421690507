import React, { Component } from "react";
import { Page } from "@shopify/polaris";
import ActionList from "./list";
import ActionEdit from "./edit";
import { CommonPageState } from "../../utils/common_state";
// import styles from './style.module.scss'

class Index extends Component {
  constructor() {
    super();

    this.state = {
      currentState: CommonPageState.ListWithItems,
      editActionData: undefined,
      listDefaultPressedButton: 5,
    };
  }
  componentDidMount() {}

  getComponentOnState() {
    let component = null;
    // let defaultPressedButton = this.state.listDefaultPressedButton
    switch (this.state.currentState) {
      case CommonPageState.ListWithItems:
        component = (
          <ActionList sendChgState={(s, editData) => this.changePageState(s, editData)}  defaultPressedBtn={this.state.listDefaultPressedButton} />
        );
        break;
      case CommonPageState.CreateItem:
        component = (
          <ActionEdit sendChgState={(s, comeIn) => this.changePageState(s, null, comeIn)} />
        );
        break;
      case CommonPageState.EditItem:
        component = (
          <ActionEdit sendChgState={(s, comeIn) => this.changePageState(s, null, comeIn)} editData={this.state.editActionData} />
        );
        break;
      default:
        component = null;
    }

    return component;
  }
  showAddAction() {
    this.setState({ currentState: CommonPageState.ItemEditOrCreate });
  }
  async changePageState(st, editActionData, comeIn) {
    // console.log(st, comeIn)
    await this.setState({ currentState: st, editActionData: editActionData, listDefaultPressedButton:  comeIn});
  }
  render() {
    return <Page>{this.getComponentOnState()}</Page>;
  }
}

export default Index;
