import React, { Fragment, useState } from "react"
import styles from './index.module.scss'
import {Button, Modal} from '@shopify/polaris';
import guideImg from '../../assets/images/guide-11.png';
import { withTranslation } from 'react-i18next';

const HowToGetImage = ({t}) => {
  const [showGuide, setShowGuide] = useState(false)

  const handleOpenGuide = () => {
    setShowGuide(true)
  }

  return <Fragment>
    <Button onClick={handleOpenGuide} plain>{t("edit.howToGetImageAddress")}</Button>
    <Modal
        open={showGuide}
        onClose={() => { setShowGuide(false) }}
        title={t("howToGetImage.title")}
        
      >
        <Modal.Section>
          <div className={styles.guide}>
            <ul>
              <li>{t("howToGetImage.one")}</li>
              <li>
              <p>{t("howToGetImage.two")}</p>
              <img src={guideImg} alt="guideimage" />
              </li>
              <li>{t("howToGetImage.three")}</li>
            </ul>
          </div>
        </Modal.Section>
      </Modal>
  </Fragment>
}

export default withTranslation()(HowToGetImage)
