import request from "../utils/request"

export function addRecommendProducts(data) {
  return request.post(`api/recommend/add_products`, data)
}

export function getRecommendProducts() {
  return request.get(`api/recommend/get_products`)
}


export function deleteRecommendProduct(productId) {
  return request.delete(`api/recommend/${productId}`)
}

export function updateRecommendProducts() {
  return request.post(`api/recommend/update_all_products`)
}

export function getRecommendProductsInfo(data) {
  return request.post(`api/recommend/get_recommend_products_info`, data)
}