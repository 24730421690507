import React from "react"
import styles from "./index.module.scss"

const SubscribeEmail = ({disabled = false, placeholder = ''}) => {

  return (<div className={styles.subscribeEmail}>
    <input type="text" placeholder={placeholder} disabled={disabled} />
  </div>)
}

export default SubscribeEmail