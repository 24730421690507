export default {
  translation: {
    app: {
      action: "Pop-Ups",
      settings: "Settings",
      suggestion: "Issues & Suggestion",
      collectionReorder: "Collection Reorder(Beta)",
      contactUs: "If you have any questions or suggestions, please contact us",
      help: "help?",
      submmitIssues: "Submit Issues",
      leaveComments: "Leave Comments?",
    },
    couponEdit: {
      title: "Coupon (automatic discount)",
      percentageOption: "Percentage",
      fixedAmountOpton: "Fixed Amount",
      percentage: "The value type of the coupon price rule. <strong>Percentage: </strong>Applies a percentage discount of value.",
      amount: "The value type of the coupon price rule. <strong>Fixed Amount: </strong>Applies a discount of value as a unit of the store's currency.",
      canFill: "can fill",
      valueType: "Value Type",
      currencySymbol: "Currency Symbol",
      discountValue: "Discount value",
      discountValueRequired: "Discount value is required",
      couponTitle: "Coupon title",
      couponTitleRequired: "Coupon title is required",
      couponSubTitle: "Coupon sub title (option)",
      gotCouponButtonText: "Got coupon button text",
      gotCouponButtonTextRequired: "Got coupon button text is required",
      couponApplyReminderTextOpt: "Coupon apply reminder text (option)",
      couponApplyReminderHelpTxt: "Remind the user that the discount will be automatically applied at checkout.",
      pageReminderText: "Page reminder text (option)",
      pageReminderHelpTxt: "After the user gets the coupon, the reminder text will be displayed at the bottom of the page. If left blank, the remind text will not be displayed.",
      couponCustomAttribute: "<<Coupon Custom Attributes>>",
      backgroundImage: "Background Image URL (Option): ",
      backgroundImgPlaceHolder: "Fill image url",
      backgroundColor: "Background Color: ",
      textColor: "Text Color:",
      buttonBackgroundColor: "Button Background Color:",
      buttonTextColor: "Button Text Color:",
      needLeftEmail: "Need customer left email to get coupon and become a subscriber",
      needLeftEmailReminder: "Reminder text",
      needLeftEmailPlaceHolder: "Please input reminder text",
      pageExpirationHoursText: "Expiration Hours",
      pageExpirationHoursHelpText: "Coupon expiration time (Hours) after user get",
      couponTips: "tips: The advantage of the automatic discount is that the user does not need to manually enter the discount code to apply the discount.",
    },
    edit: {
      previewTitle: 'Preview',
      emailReminderText: "Just leave your email",
      leavePageOptionText: "Leave current page (click back button or sweep finger)",
      stayOnPageOptionText: "Stay on the page some seconds",
      scrollBottomOptionText: "Scroll to page bottom",
      addToCartOptionText: "Click [Add to Cart] button",
      addDoNothingText: "Do nothing(immediately)",
      pngImageDesc: "PNG images with transparent background can get the best effects",
      howToGetImageAddress: "How to get image URL address",
      coupon: {
        title: "Congratulations!",
        subTitle: "You are very lucky\nwon a COUPON",
        gotButtonText: "Get it",
        applyRemindText: "*It will be applied automatically at checkout*",
        pageRemindText: "You have an unused coupon, checkout now",
      },
      survey: {
        title: "Meet Issues?",
        subTitle: "Is there anything preventing you from purchase this product?",
        otherText: "Other (please specify)",
        submitText: "SUBMIT",
        option1: "Option1",
        option2: "Option2",
      },
      subscriber: {
        title: "Sign up for our Newsletter",
        subTitle: "Be the first to learn about our latest trends and get exclusive offers",
        unSubDesc: "We won't send you spam. Unsubscribe at any time.",
      },
      upsell: {
        title: "Hot Products",
        addToCartText: "Add to Cart",
      },
      cart: {
        fireAtLeastOneItem: "At least 1 item in cart",
      },
      alert: {
        pleaseSelectProducts: "Please select products.",
        pleaseSelectCollections: "Please select collections.",
      },
      comeIn: {
        anyProductDetailPage: "Any product detail page (landing page)",
        anyCollectionPage: "Any collection page (collections page)",
        shoppingCart: "Shopping cart",
        specialProductDetailPage: "Special product detail page",
        specialCollectionPage: "Special collection page",
        homePage: "Home page",
        paySuccessedPage: "Pay successed page (ThankYou page)",
      },
      doAction: {
        popUpCoupon: "Pop Up Coupon (Apply to Entire Order)",
        popUpSurvey: "Pop Up the Survey",
        recommendProducts: "Recommended Products (Upsell)",
        popUpSubscribe: "Pop Up Subscription Window",
      },
      buttonText: {
        editAction: "Edit Pop-ups",
        addAction: "Add Pop-ups",
        update: "Update",
        save: "Save",
      },
      page: {
        actions: "Pop-ups",
      },
      actionAttr: {
        name: "Name",
        namePlaceHolder: "Pop-ups name",
        nameError: "Pop-ups name is required",
        userComesIn: "When then user comes in",
        userDoseAction: "User does this action",
        staySeconds: "Stay seconds",
        stayPlaceHolder: "1-99",
        stayError: "Stay seconds is required and must be greater than zero",
        thenTriggerAction: "Then to trigger Pop-ups",
        leavePageDesc: "The [leave current page] here means that the user clicks the back button of the browser, or on the mobile phone, the user slides his finger to close the browser.",
        selectSpecialProduct: "Select special product",
        cartTriggerCondition: "Cart trigger conditions",
        triggerOnDevice: "Trigger on device",
        allDevice: "All device",
        mobile: "Mobile",
        pc: "PC",
        enableURLRestrict: "Only triggered when the page URL contains the following characters, such as utm_source=facebook",
        urlPatternPlaceholder: "Enter part of the page UR",
        urlPatternError: "Please enter part of the page URL",
      }
    },
    list: {
      bannerTitle: "Before creating Pop-ups, please make sure to follow the steps in [settings] to make the necessary settings",
      bannerReminder: "If you have already set it up, please ignore this message.",
      emptyHeading: "Click button to create Pop-ups",
      emptyAction: "Add Pop-ups",
      emptyActiveAction: "There is no active Pop-ups. You can active any Pop-ups from UnActive list.",
      comeIn: {
        anyProductPage: "Come in ANY product page",
        anyCollectionPage: "Come in ANY collection page",
        shoppingCart: "Come in Shopping cart",
        specialProductDetailPage: "Come in SPECIAL product page",
        specialCollectionPage: "Come in SPECIAL collection page",
        homePage: "Come in Home page",
        paySuccessedPage: "Come in Pay successed page",
      },
      doAction: {
        leavePage: "When user leave",
        staySeconds: "When user stay {{seconds}} seconds",
        scrollToBottom: "When user scroll to page bottom",
        clickAddToCart: "When user click Add to Cart button",
        doNothing: "Do nothing(immediately)",
      },
      fireAction: {
        popUpCoupon: "Pop Up COUPON",
        popUpSurvey: "Pop Up the Survey",
        recommendProducts: "Recommend Products(UpSell)",
        popUpSubscribe: "Pop Up Subscription Window",
      },
      device: {
        all: "All device",
        mobile: "Mobile",
        pc: "PC",
      },
      noneSubscribers: "Not any Subscribers",
      alert: {
        areYouDelete: "Are you sure delete this Pop-ups?",
        areYouActive: "Are you sure active this Pop-ups?",
        areYouDisable: "Are you sure disable this Pop-ups?",
      },
      buildTable: {
        discount: "discount",
        delete: "Delete",
        active: "Active",
        edit: "Edit",
      },
      tableList: {
        name: "Name",
        userActionFlow: "User action flow",
        createTime: "Create time",
      },
      stCouponUi: {
        trigger: "Trigger",
        sessions: "sessions",
        gotCoupon: "Got coupon",
        closeCoupon: "Close coupon",
        placeOrder: "Place order",
        totalAmountOfOrders: "Total amount of orders",
      },
      stSurveyUi: {
        trigger: "Trigger",
        sessions: "sessions",
        submitSurvey: "Submit survey",
        closeSurvey: "Close survey",
        optionAnswers: "Option anwsers",
        otherAnswers: "Other anwsers",
      },
      stSubscribeUi: {
        trigger: "Trigger",
        sessions: "sessions",
        gotSubscribe: "Become a subscriber",
        closeSubscribe: "Close subscription window",
      },
      stRecommendPtUi: {
        trigger: "Trigger",
        sessions: "sessions",
        addToCart: "Add to Cart",
        add: "add",
        times: "times",
      },
      hideAction: {
        hideCoupon: "Hide coupon preview",
        hideSurvey: "Hide survey preview",
        hideRecommendPt: "Hide recommend products preview",
      },
      previewAction: {
        previewCoupon: "Preview coupon",
        previewSurvey: "Preview survey",
        previewRecommendPt: "Preview recommend products",
        previewSubscribe: "Preview subscription",
      },
      itemForm: {
        editAction: "Edit Pop-ups",
        hideStatistic: "Hide statistics",
        showStatistic: "Show statistics",
        active: "Active",
        disable: "Disable",
        userActionFlow: "User action flow",
        couponDiscount: "Coupon discount",
        status: "Status",
        displayMethod: "Display method",
        unActive: "UnActive",
        online: "Online",
        triggerOn: "Trigger on",
        needLeftEmail: "Need left email",
        subscribers: "Subscribers",
        createTime: "create time",
        couponPreview: "Coupon preview",
        surveyPreview: "Survey preview",
        recommendPtPreview: "Recommend products preview",
        subscriptionPreview: "Subscription preview",
        statistics: "Statistics",
        enableURLRestrict: "Trigger URL restrictions",
        urlPatternDesc: "Triggered only when the page URL contains the following characters",
      },
      freeplan: {
        title: "You are on the FREE Plan",
        actionLimit: "You can only add up to {{maxActions}} Pop-ups",
        feature1: "All Pop-ups, (include Coupon, Survey), support user customization",
        feature2: "You can customize the pop-up strategy",
        feature3: "Get Data analytics",
        changePlan: "Change Plan",
      },
      basicPlan: {
        changePlan: "Change Plan",
        basicPlan: "BASIC PLAN",
        eachMonthFee: "$5.99/month",
        sevenDaysFree: "7-days free trial",
        feature1: "Unlimited Pop-ups",
        feature2: "All Pop-ups, (include Coupon, Survey, Subscription window), support user customization",
        feature3: "Recommend products to customer when trigger Pop-ups (UpSell)",
        feature4: "You can customize the pop-up strategy",
        feature5: "Get Data analytics",
        upgrade: "Upgrade",
        upgrading: "Upgrade...",
      },
      rt: {
        actions: "Pop-ups",
        addAction: "Add Pop-ups",
        productDetailPage: "Product detail page",
        collectionPage: "Collection page",
        shoppingCartPage: "Shopping cart page",
        homePage: "Home page",
        paySuccessedPage: "Pay successed page",
        unactiveActionList: "UnActive Pop-ups list",
      }

    },
    cartCondition: {
      cartValue: "Cart value",
      cartQuantity: "Cart quantity",
      lessThan: "Less than",
      greaterTo: "Greater to",
      totalPriceHolder: "Total Price",
      add: "Add",
    },
    collectionList: {
      title: "Collections List",
    },
    issueSuggestion: {
      successMessage: "We have received your message, Thank You !",
      plsTellUs: "Please tell us if you have any question ✍️",
      issue: "Issue",
      suggestion: "Suggestion",
      atLeastTenWords: "At least 10 words.",
      submit: "Submit",
    },
    productSearch: {
      searchPlaceHolder: "Search by product title and press Enter key",
      addProductTitle: "Add products",
      add: "Add",
      cancel: "Cancel",
      searchPtPlaceHolder: "Search products",
      searchHelpText: "Search by product title and press Enter key",
      noResultFor: "No results found for",
    },
    productList: {
      inStockFor: "in stock for",
      variants: "variants",
    },
    selectedList: {
      remove: "remove",
    },
    settings: {
      copySuccessed: "Copy to clipboard successful",
      installSpec: "Installation specifications",
      inOrderTo: "In order to obtain the order statistics brought by the coupon, please follow the steps below to set up.",
      list1: "Log in the store admin panel, click [settings] in the lower-left corner.",
      list2: "To click the [Checkout] menu item on the left.",
      list3: "Scroll down to the [Additional scripts] setting.",
      list4: "Paste the following template script into the [Order status page] text box.",
      list5: "Click the [Save] button to save the setting, It's done.",
      templateScript: "Template script",
      clicToCopy: "Click to copy",
      explanationLegd: "Explanation of operation legend",
    },
    surveyEdit: {
      caption: "Survey",
      removeButton: "remove",
      title: "Survey title",
      titleError: "Survey title is required",
      subTitle: "Survey sub title (option)",
      otherOptionName: "Other option name",
      otherOptionError: "Other option name is required",
      submitButton: "Submit button text",
      submitButtonError: "Submit button text is required",
      fillSurveyPlaceHolder: "Fill survey option here",
      fillSurveyHelpText: "You can only add up to",
      addOption: "Add option",
      surveyCustomAttr: "<<Survey Custom Attributes>>",
      bkGroundImgOpt: "Background Image URL (Option): ",
      fillImgUrl: "Fill image url",
      bkGroundColor: "Background Color: ",
      textColor: "Text Color:",
      buttonBkGroundColor: "Button Background Color:",
      buttonTextColor: "Button Text Color:",
      hideCloseButton: "Hide survey close button",
      canGetCouponText: "Can customers receive a coupon if they complete this survey? ",
      couponCode: "Coupon Code",
      couponCodePlaceholder: "the code of a coupon",
      getCouponTips: "Get coupon tips",
      getCouponTipsPlaceholder: "tips for the customer after they completed this survey",
      getCouponGuid1: "You can create a coupon from ",
      getCouponGuid2: "and get the code of Coupon to fill in this textbox.",
    },
    subscribeEdit: {
      cardTitle: "Subscription",
      title: "Title",
      titleError: "Subscribe title is required",
      subTitle: "Sub title (option)",
      unSubDesc: "Unsubscribe statement",
      unSubDescError: "Unsubscribe statement is required",

      placeHolderText: "Email place holder text",
      placeHolderError: "Email place holder text is required",
      btnText: "Subscribe button text",
      btnTextError: "Subscribe button text is required",

      cardPreview: "Preview",
      subscribeCustomAttr: "<<Subscribtion Custom Attributes>>",
      bkGroundColor: "Background Color: ",
      textColor: "Text Color:",
      buttonBkGroundColor: "Button Background Color:",
      buttonTextColor: "Button Text Color:",
    },
    upsell: {
      productList: {
        inStockFor: "in stock for",
        variants: "variants",
      },
      recommendPts: {
        deleteAlert: "Are you sure to delete this product?",
      },
      edit: {
        updateRecommendProducts: "Update recommend products information",
        upsellRecommentPtsToUser: "UpSell - Recommend products to users",
        searchByProduct: "Search by product title and press Enter key",
        modalTitle: "Add products",
        primaryAddBtn: "Add",
        secondCancelBtn: "Cancel",
        modalSearchPlaceHolder: "Search products",
        modalSearchHelpText: "Search by product title and press Enter key",
        noResultFound: "No results found for",
        recommendProductsAttr: "<<Recommend Products Custom Attributes>>",
        upsellTitle: "Upsell title: ",
        fillTitlePlaceHolder: "Fill title",
        addToCartText: "Add to Cart Text: ",
        fillAddToCartPlaceHolder: "Fill add to cart text",
        addToCartButtonTxtColor: "Add to Cart Button Text Color: ",
        addToCartButtonBkColor: "Add to Cart Button Background Color: ",
        displayWay: "Display method",
        displayPopUpOpt: "Pop-Up window",
        displayBelowATCBtnOpt: "Below [Add to Cart] button",
        displayInlineThankYouPageOpt: "Inline to Thank You page",
      }
    },
    howToGetImage: {
      title: "Guide to get image URL address",
      one: "Open any website page (your store site is the best choice) which contains images.",
      tow: "Place the mouse cursor over the picture and click the right mouse button.",
      three: 'Click [Copy Image Address] menu item, and paste it into the "Background Image URL" text box. Done!',
    },
    leaveComments: {
      content: "Could you leave a comment? Thank you.",
      clickHere: "click here",
    },
    httpRequest: {
      authFailed: "The session has expired, the program will automatically refresh the page.",
    },
    collectionReorder: {
      title: "Collection ReOrder - Feature introduction",
      content: `
        
      `
    }
  }
}
