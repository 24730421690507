import React, {useState, useCallback} from "react"
import {Page, Layout, Card, List, Stack, Button, Toast, Frame} from '@shopify/polaris';
import styles from './index.module.scss'
import chkImg1 from '../../assets/images/1-1.png';
import chkImg2 from '../../assets/images/2-2.png';
import chkImg3 from '../../assets/images/3-3.png';
import { withTranslation } from 'react-i18next';

const Settings = ({t}) => {
  const [active, setActive] = useState(false);
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const toastMarkup = active ? (
    <Toast content={t("settings.copySuccessed")} onDismiss={toggleActive} duration={2500} />
  ) : null;

  const hostDomain = process.env.DOMAIN
  const templateScirpts = `<script type="text/javascript" >
  (function (t, r, a, f, y, x) {
  if ('whenleave_chk' in t || t.whenleave_chk === true) { return true; }
  window.whenleave_chk = true;
  y = r.createElement(a); y.src = f; y.async = true;
  x = r.querySelector(a); x.parentNode.insertBefore(y, x);
  })(window, document, 'script', '//${hostDomain}/js/chk_callback.js?v=' + (new Date().getTime()));
  </script>`

  const copyScripts = () => {
    // console.log(templateScirpts)
    fallbackCopyTextToClipboard(templateScirpts)
  }
  const fallbackCopyTextToClipboard = (text) => {
    let textArea = document.createElement("textarea")
    textArea.value = text
    textArea.style.position="fixed"  //avoid scrolling to bottom
    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()
  
    try {
      let successful = document.execCommand('copy')
      if(successful) {
        setActive(true)
      }
      // console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
      // console.error('Fallback: Oops, unable to copy', err);
    }
  
    document.body.removeChild(textArea);
  }
  return (
    <>
     <Frame>
      <Page fullWidth>
        <Layout>
          <Layout.Section>
            <Card title={t("settings.installSpec")} sectioned>
            <Stack spacing="loose" vertical>
              <p>{t("settings.inOrderTo")}</p>
              <List type="number">
                <List.Item>{t("settings.list1")}</List.Item>
                <List.Item>{t("settings.list2")}</List.Item>
                <List.Item>{t("settings.list3")}</List.Item>
                <List.Item>{t("settings.list4")}</List.Item>
                <List.Item>{t("settings.list5")}</List.Item>
              </List>
            </Stack>
            
            <div>
              <div className={styles.templateTitle}>{t("settings.templateScript")}</div>
              <div className={styles.templateCode}>
                <Button plain onClick={copyScripts}>{t("settings.clicToCopy")}</Button>
                <p>{`<script type="text/javascript" >`}</p>
                <p>{`(function (t, r, a, f, y, x) {`}</p>
                <p>{`if ('whenleave_chk' in t || t.whenleave_chk === true) { return true; }`}</p>
                <p>{`window.whenleave_chk = true;`}</p>
                <p>{`y = r.createElement(a); y.src = f; y.async = true;`}</p>
                <p>{`x = r.querySelector(a); x.parentNode.insertBefore(y, x);`}</p>
                <p>{`})(window, document, 'script', '//app.goldengate.work/js/chk_callback.js?v=' + (new Date().getTime()));`}</p>
                <p>{`</script>`}</p>
              </div>
              {toastMarkup}
            </div>
            <div>
              <div className={styles.templateTitle}>{t("settings.explanationLegd")}</div>
              <div className={styles.optImgBox}>
                <img src={chkImg1} alt="operations" />
              </div>
              <div className={styles.optImgBox}>
                <img src={chkImg2} alt="operations" />
              </div>  
              <div className={styles.optImgBox}>
                <img src={chkImg3} alt="operations" />
              </div>              
            </div>
            </Card>
            
          </Layout.Section>
        </Layout>
      </Page>
    </Frame>
    </>
  )
}
export default withTranslation()(Settings)
