import React, { useState, Fragment, useContext } from "react"
import SurveyPreview from "./surveyPreview";
import {Button, Card,  FormLayout, Layout, TextField, Checkbox} from '@shopify/polaris';
import { Splitter } from "../commonLib";
import styles from "./surveyEdit.module.scss";
import LabelPickColor from  "../labelColorPicker"
import { withTranslation } from 'react-i18next';
import HowToGetImage from "../howToGetImage";
import { AppContext } from "../../context-manager";
import {useAppBridge} from "@shopify/app-bridge-react";
import {Redirect} from "@shopify/app-bridge/actions"
const SurveyEdit = ({surveyInfo = {}, onChange, onError, t}) => {
  const maxPresetOptions = 6
  const app = useAppBridge()
  const redirect = Redirect.create(app)
  // const { shopInfo } = useContext(AppContext)
  const [survey, setSurvey] = useState(surveyInfo)
  const [surveyOption, setSurveyOption] = useState('')

  const [validation, setValidation] = useState({
    surveyTitleErr: false,
    surveyOtherTxtErr: false,
    surveySubmitTxtErr: false
  })

  const handleSurveyAttrChange = (s) => {
    setSurvey(s)
    onChange && onChange(s)
  }

  const handleAddOption = () => {
    if(surveyOption !== '' && survey.options.length < maxPresetOptions) {
      const tempOpts = [...survey.options, {id: survey.options.length + 1, title: surveyOption}]

      const s = {...survey, options: tempOpts}
      setSurvey(s)
      setSurveyOption('')
      onChange && onChange(s)
    }
  }

  const handleAddOptionKeyPress = (event) => {
    console.log(event)
    if (event.keyCode === 13) {
      event.preventDefault();
      handleAddOption()
    }
  }

  const onSurveyTitleMoved =  () => {
    if(survey.title === "") {
      setValidation({...validation,  surveyTitleErr: true})
      onError && onError(true)
    } else {
      setValidation({...validation,  surveyTitleErr: false})
    }
  }
  
  const onSurveyOtherTxtMoved =  () => {
    if(survey.title === "") {
      setValidation({...validation,  surveyOtherTxtErr: true})
      onError && onError(true)
    } else {
      setValidation({...validation,  surveyOtherTxtErr: false})
    }
  }
  const onSurveySubmitTxtMoved =  () => {
    if(survey.title === "") {
      setValidation({...validation,  surveySubmitTxtErr: true})
      onError && onError(true)
    } else {
      setValidation({...validation,  surveySubmitTxtErr: false})
    }
  }
  const onRemoveOption = (item) => {
    const opts = survey.options.filter(opt => opt.id !== item.id)
    const s  = {...survey, options: opts}
    setSurvey(s)
    onChange && onChange(s)

  }

  const navigateToDiscountPage = () => {
    redirect.dispatch(Redirect.Action.ADMIN_PATH, {
      path: '/discounts',
      newContext: false,
    });
  }
  // const supportEmoji = <span>Can fill <a href="https://getemoji.com/" target="_blank" rel="noopener noreferrer">emoji</a></span>

  const optionList = survey.options.map((item,index) => {
    return <tr key={item.id}>
      <td>{index + 1}. {item.title}</td>
      {survey.canModifyOption && <td><Button plain="true" onClick={() => { onRemoveOption(item) }}>{t("surveyEdit.removeButton")}</Button></td>}
    </tr>
  })

  return (
    <Fragment>
      <Card title={t("surveyEdit.caption")} sectioned> 
      <FormLayout>
          <FormLayout.Group>
          <TextField value={survey.title} 
                  onChange={(v)=> { handleSurveyAttrChange({...survey, title: v})}} 
                  label={t("surveyEdit.title")} 
                  type="text"
                  placeholder=""
                  min="0"
                  max="90"
                  onBlur={onSurveyTitleMoved}
                  error={validation.surveyTitleErr ? t("surveyEdit.titleError") : ""}
                  helpText={<span></span>}
            />
            <TextField value={survey.subTitle} 
                  onChange={(v)=> { handleSurveyAttrChange({...survey, subTitle: v})}} 
                  label={t("surveyEdit.subTitle")} 
                  type="text" placeholder=""
                  multiline={2}
                  min="0"
                  max="150"
                  helpText={<span></span>}
            />
          </FormLayout.Group>
          <FormLayout.Group>
          <TextField value={survey.otherText} 
                  onChange={(v)=> { handleSurveyAttrChange({...survey, otherText: v})}} 
                  label={t("surveyEdit.otherOptionName")}  
                  type="text"
                  placeholder=""
                  min="0"
                  max="50"
                  onBlur={onSurveyOtherTxtMoved}
                  error={validation.surveyOtherTxtErr ? t("surveyEdit.otherOptionError") : ""}
                  helpText={<span></span>}
            />
            <TextField value={survey.submitText} 
                    onChange={(v)=> { handleSurveyAttrChange({...survey, submitText: v})}} 
                    label={t("surveyEdit.submitButton")} 
                    type="text"
                    placeholder=""
                    min="0"
                    max="50"
                    onBlur={onSurveySubmitTxtMoved}
                    error={validation.surveySubmitTxtErr ? t("surveyEdit.submitButtonError") : ""}
                    helpText={<span></span>}
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <Checkbox label={t("surveyEdit.canGetCouponText")} checked={ survey.canGetCouponIfDone } 
              onChange={(v)=> { handleSurveyAttrChange({...survey, canGetCouponIfDone: v})}}
            />
          </FormLayout.Group>
          {
            survey.canGetCouponIfDone && <FormLayout.Group>
              <TextField value={survey.couponCode} 
                    onChange={(v)=> { handleSurveyAttrChange({...survey, couponCode: v})}} 
                    label={t("surveyEdit.couponCode")}
                    type="text"
                    placeholder={t("surveyEdit.couponCodePlaceholder")}
                    min="0"
                    max="50"
                    onBlur={onSurveySubmitTxtMoved}
                    helpText={<span>
                      {t("surveyEdit.getCouponGuid1")} <Button plain="true" size="medium" onClick={navigateToDiscountPage}>[Discounts]</Button> {t("surveyEdit.getCouponGuid2")}
                      </span>
                    }
              />
              <TextField value={survey.couponRemindText} 
                    onChange={(v)=> { handleSurveyAttrChange({...survey, couponRemindText: v})}} 
                    label={t("surveyEdit.getCouponTips")}
                    type="text"
                    placeholder={t("surveyEdit.getCouponTipsPlaceholder")}
                    min="0"
                    max="50"
                    onBlur={onSurveySubmitTxtMoved}
                    helpText={<span></span>}
              />
            </FormLayout.Group>
          }
          <Splitter />
          <FormLayout.Group>
            <span onKeyDown={handleAddOptionKeyPress}>
            <TextField value={surveyOption} 
                    onChange={(v)=> { setSurveyOption(v) }} 
                    label="" 
                    type="text"
                    min="0"
                    max="50"
                    disabled={!survey.canModifyOption}
                    placeholder={t("surveyEdit.fillSurveyPlaceHolder")}
                    helpText={<span>{t("surveyEdit.fillSurveyHelpText")} {maxPresetOptions}</span>}
            />
            </span>
            <Button disabled={surveyOption === ''} onClick={handleAddOption}>{t("surveyEdit.addOption")}</Button>           
          </FormLayout.Group>
          {<table className={styles.optionsTable}>
            <tbody>
              {optionList}
            </tbody>
          </table>  
          }
          <FormLayout.Group>
            
          </FormLayout.Group>
         
      </FormLayout>
      
      </Card>
      <Card title={t("edit.previewTitle")} sectioned>
        <Layout>
          <Layout.Section>
            <SurveyPreview survey={survey} />
          </Layout.Section>
          <Layout.Section secondary>
          <div className={styles.colorPanel}>
              <label className={styles.customAttrs}>{t("surveyEdit.surveyCustomAttr")}</label>
              <div className={styles.row}>
                  <TextField value={survey.bgImage} 
                    onChange={(v)=> { handleSurveyAttrChange({...survey, bgImage: v})}} 
                    label={t("surveyEdit.bkGroundImgOpt")} 
                    type="text" 
                    multiline={2}
                    placeholder={t("surveyEdit.fillImgUrl")}
                  />
              </div>
              <div className={styles.png}>{t("edit.pngImageDesc")}</div>
              <div className={styles.row}>
                <HowToGetImage />
              </div>
              <div className={styles.row}>
                <p>{t("surveyEdit.bkGroundColor")}</p>
                  <LabelPickColor color={ survey.bgColor } onChangeComplete={ (v)=> { handleSurveyAttrChange({...survey, bgColor: v.hex})} }/>
              </div>
 
              <div className={styles.row}>
                  <p>{t("surveyEdit.textColor")}</p>
                  <LabelPickColor color={ survey.textColor } onChangeComplete={ (v)=> { handleSurveyAttrChange({...survey, textColor: v.hex})} }/>
                </div>
              <div className={styles.row}>
                  <p>{t("surveyEdit.buttonBkGroundColor")}</p>
                  <LabelPickColor color={ survey.btnBgColor } onChangeComplete={ (v)=> { handleSurveyAttrChange({...survey, btnBgColor: v.hex})} }/>
                </div>
              <div className={styles.row}>
                  <p>{t("surveyEdit.buttonTextColor")}</p>
                  <LabelPickColor color={ survey.btnTextColor } onChangeComplete={ (v)=> { handleSurveyAttrChange({...survey, btnTextColor: v.hex})} }/>
              </div>

              <div className={styles.row}>
              <Checkbox label={t("surveyEdit.hideCloseButton")} checked={ survey.hideCloseButton } onChange={(v)=> { handleSurveyAttrChange({...survey, hideCloseButton: v})}}/>
              </div>
            </div>            
          </Layout.Section>
        </Layout>

      </Card>
    </Fragment>
  )
}
export default withTranslation()(SurveyEdit)
