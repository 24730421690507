import React, { useState, Fragment } from "react"
import SubscribePreview from "./preview";
import {Card,  FormLayout, Layout, TextField} from '@shopify/polaris';
// import { Splitter } from "../commonLib";
import styles from "./edit.module.scss";
import LabelPickColor from  "../labelColorPicker"
import { withTranslation } from 'react-i18next';
import HowToGetImage from "../howToGetImage";
const SubscriberEdit = ({subscriberInfo = {}, onChange, onError, t}) => {
  const [subscriber, setSubscriber] = useState(subscriberInfo)

  const [validation, setValidation] = useState({
    subscribeTitleErr: false,
    subscribePlaceholderErr: false,
    subscribeBtnTextErr: false,
    subscribeUnSubDescErr: false,
  })

  const handleSubscriberAttrChange = (s) => {
    setSubscriber(s)
    onChange && onChange(s)
  }

  const onSubscribeTitleMoved =  () => {
    if(subscriber.title === "") {
      setValidation({...validation,  subscribeTitleErr: true})
      onError && onError(true)
    } else {
      setValidation({...validation,  subscribeTitleErr: false})
    }
  }
  const onSubscribeUnSubDescMoved =  () => {
    if(subscriber.unSubDesc === "") {
      setValidation({...validation,  subscribeUnSubDescErr: true})
      onError && onError(true)
    } else {
      setValidation({...validation,  subscribeUnSubDescErr: false})
    }
  }

  const onSubscribePlaceHolderMoved =  () => {
    if(subscriber.placeHolderText === "") {
      setValidation({...validation,  subscribePlaceholderErr: true})
      onError && onError(true)
    } else {
      setValidation({...validation,  subscribePlaceholderErr: false})
    }
  }

  const onSubscribeBtnTextMoved =  () => {
    if(subscriber.btnText === "") {
      setValidation({...validation,  subscribeBtnTextErr: true})
      onError && onError(true)
    } else {
      setValidation({...validation,  subscribeBtnTextErr: false})
    }
  }

  return <Fragment>
      <Card title={t("subscribeEdit.cardTitle")} sectioned> 
      <FormLayout>
          <FormLayout.Group>
          <TextField value={subscriber.title} 
                  onChange={(v)=> { handleSubscriberAttrChange({...subscriber, title: v})}} 
                  label={t("subscribeEdit.title")} 
                  type="text"
                  placeholder=""
                  min="0"
                  max="90"
                  onBlur={onSubscribeTitleMoved}
                  error={validation.subscribeTitleErr ? t("surveyEdit.titleError") : ""}
                  helpText={<span></span>}
            />
            <TextField value={subscriber.subTitle} 
                  onChange={(v)=> { handleSubscriberAttrChange({...subscriber, subTitle: v})}} 
                  label={t("subscribeEdit.subTitle")} 
                  type="text" placeholder=""
                  multiline={2}
                  min="0"
                  max="150"
                  helpText={<span></span>}
            />
          </FormLayout.Group>
          <FormLayout.Group>
          <TextField value={subscriber.placeHolderText} 
                  onChange={(v)=> { handleSubscriberAttrChange({...subscriber, placeHolderText: v})}} 
                  label={t("subscribeEdit.placeHolderText")} 
                  type="text"
                  placeholder=""
                  min="0"
                  max="80"
                  onBlur={onSubscribePlaceHolderMoved}
                  error={validation.subscribePlaceholderErr ? t("subscribeEdit.placeHolderError") : ""}
                  helpText={<span></span>}
            />
          <TextField value={subscriber.btnText} 
                  onChange={(v)=> { handleSubscriberAttrChange({...subscriber, btnText: v})}} 
                  label={t("subscribeEdit.btnText")} 
                  type="text"
                  placeholder=""
                  min="0"
                  max="60"
                  onBlur={onSubscribeBtnTextMoved}
                  error={validation.subscribeBtnTextErr ? t("subscribeEdit.btnTextError") : ""}
                  helpText={<span></span>}
            />            
          </FormLayout.Group>
          <FormLayout.Group>
          <TextField value={subscriber.unSubDesc} 
                  onChange={(v)=> { handleSubscriberAttrChange({...subscriber, unSubDesc: v})}} 
                  label={t("subscribeEdit.unSubDesc")} 
                  type="text"
                  placeholder=""
                  min="0"
                  max="90"
                  onBlur={onSubscribeUnSubDescMoved}
                  error={validation.subscribeUnSubDescErr ? t("subscribeEdit.unSubDescError") : ""}
                  helpText={<span></span>}
            />
          </FormLayout.Group>
      </FormLayout>
      </Card>
      <Card title={t("subscribeEdit.cardPreview")} sectioned>
        <Layout>
          <Layout.Section>
            <SubscribePreview subscriber={subscriber} />
          </Layout.Section>
          <Layout.Section secondary>
          <div className={styles.colorPanel}>
              <label className={styles.customAttrs}>{t("subscribeEdit.subscribeCustomAttr")}</label>
              <div className={styles.row}>
                  <TextField value={subscriber.bgImage} 
                    onChange={(v)=> { handleSubscriberAttrChange({...subscriber, bgImage: v})}} 
                    label={t("surveyEdit.bkGroundImgOpt")} 
                    type="text" 
                    multiline={2}
                    placeholder={t("surveyEdit.fillImgUrl")}
                  />
              </div>
              <div className={styles.png}>{t("edit.pngImageDesc")}</div>
              <div className={styles.row}>
                <HowToGetImage />
              </div>
              <div className={styles.row}>
                <p>{t("subscribeEdit.bkGroundColor")}</p>
                  <LabelPickColor color={ subscriber.bkColor } onChangeComplete={ (v)=> { handleSubscriberAttrChange({...subscriber, bkColor: v.hex})} }/>
              </div>
 
              <div className={styles.row}>
                  <p>{t("subscribeEdit.textColor")}</p>
                  <LabelPickColor color={ subscriber.textColor } onChangeComplete={ (v)=> { handleSubscriberAttrChange({...subscriber, textColor: v.hex})} }/>
                </div>
              <div className={styles.row}>
                  <p>{t("subscribeEdit.buttonBkGroundColor")}</p>
                  <LabelPickColor color={ subscriber.btnBkColor } onChangeComplete={ (v)=> { handleSubscriberAttrChange({...subscriber, btnBkColor: v.hex})} }/>
                </div>
              <div className={styles.row}>
                  <p>{t("subscribeEdit.buttonTextColor")}</p>
                  <LabelPickColor color={ subscriber.btnTextColor } onChangeComplete={ (v)=> { handleSubscriberAttrChange({...subscriber, btnTextColor: v.hex})} }/>
              </div>
            </div>            
          </Layout.Section>
        </Layout>

      </Card>
  </Fragment>

}

export default withTranslation()(SubscriberEdit)
