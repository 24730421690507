import React, { useState, useCallback , Fragment} from "react"
import { Card, Scrollable, Modal, Button, TextField, Icon, Select } from '@shopify/polaris';
import { SearchMinor } from '@shopify/polaris-icons';
import { FormLayout } from "@shopify/polaris";
import ProductList from './product_list';
import RecommendProductView from './recommend_product_view';
import { searchProducts } from '../../api/shop'
import { getRecommendProductsInfo } from '../../api/recommend'
import LabelPickColor from  "../labelColorPicker"
import styles from "./upsellEdit.module.scss"
import { withTranslation } from 'react-i18next';

const UpsellEdit = ({upsellInfo = {}, upsellProducts = [], actionId = 0, onChange, onAttrChange, comeIn, t}) => {
  // const sampleProducts = [{
  //   platformId: 1000,
  //   checked: false,
  //   image: "https://cdn.shopifycdn.net/s/files/1/0421/0504/5155/products/3be050e9a1092f0c0ec12672e4dcecbd_76920ae0-5b1f-4ed3-909b-55edc31043c8_200x200.jpg?v=1604629122",
  //   title: "4 colors luminous beads moon pumpkin necklace moon pendant luminous necklace",
  //   skus: [
  //     {
  //       platformId: 2000,
  //       checked: false,
  //       title: "Black gray#01",
  //       inventoryQuantity: 2,
  //       price: 3.99,
        
  //     }
  //   ]
  // },
  // {
  //   platformId: 1001,
  //   checked: false,
  //   image: "https://cdn.shopifycdn.net/s/files/1/0421/0504/5155/products/3be050e9a1092f0c0ec12672e4dcecbd_76920ae0-5b1f-4ed3-909b-55edc31043c8_200x200.jpg?v=1604629122",
  //   title: "5 colors luminous beads moon pumpkin necklace moon pendant luminous necklace",
  //   skus: [
  //     {
  //       platformId: 2001,
  //       checked: false,
  //       title: "Black gray#01",
  //       inventoryQuantity: 2,
  //       price: 3.99,
        
  //     },
  //     {
  //       platformId: 2002,
  //       checked: false,
  //       title: "Black gray#01",
  //       inventoryQuantity: 2,
  //       price: 3.99,
        
  //     }
  //   ]
  // },
  // ]

  const [showModal, setShowModal] = useState(false)
  const [focusSearch, setFocusSearch] = useState(false)
  const [preTitle, setPreTitle] = useState("")
  const [searchTitle, setSearchTitle] = useState("")
  const [products, setProducts] = useState(null)
  // const [disableAddButton, setDisableAddButton] = useState(true)
  const [selectedProducts, setSelectedProducts] = useState([])
  const [recommendProducts, setRecommendProducts] = useState(upsellProducts || [])

  const [upsell, setUpsell] = useState(upsellInfo)

  const popUpWindowOption = {label: t("upsell.edit.displayPopUpOpt"), value: '0'}
  const belowATCBtnOption = {label: t("upsell.edit.displayBelowATCBtnOpt"), value: '1'}
  const inlineThankYouPageOption = {label: t("upsell.edit.displayInlineThankYouPageOpt"), value: '2'}

  if(upsell && upsell.displayMethod) {
    upsell.displayMethod = upsell.displayMethod.toString()
  }
  let upsellDisplayOptions = [
    popUpWindowOption
  ]

  if(comeIn) {
    switch(parseInt(comeIn)) {
      case 0:
      case 3:
        upsellDisplayOptions = [popUpWindowOption, belowATCBtnOption]
        break
      case 6:
        upsellDisplayOptions = [popUpWindowOption, inlineThankYouPageOption]
        break
      default:
        break
    }
  }
  // const [currentShopDomain, setCurrentShopDomain] = useState("")

  // const loadRecommendProducts = () => {
  //   getRecommendProducts().then(data => {
  //     setRecommendProducts(data.recommend_products || [])
  //     // setCurrentShopDomain(data.domain)
  //   })
  // }

  // useEffect(loadRecommendProducts, []);

  // const titleChange = useCallback((tl) => {
  //   setSearchTitle(tl)
  //   if (tl && tl.trim() !== "") {
  //     setShowModal(true)
  //   }

  // }, [])

  const handleUpsellAttrChange = (newValue) => {
    setUpsell(newValue)
    onAttrChange && onAttrChange(newValue)
  }

  const preTitleChange = (title) => {
    setPreTitle(title)
  }

  const modalTitleChange =  (title) => {
    setSearchTitle(title)
  }
  // const previewOnClick = () => {
  //   // if (preTitle !== "") {
  //   //   setSearchTitle(preTitle)
  //   //   setPreTitle("")
  //   //   setShowModal(true)
  //   // }
  //   // handlePreSearchKeyPress({keyCode: 13})
  //   const url = `https://${currentShopDomain}/cart`
  //   if(window.top) {
  //     window.top.location.href = url
  //   } else {
  //     window.location.href = url
  //   }
    
  // }

  const updateProductsOnClick = () => {
    // console.log('update...')
    // updateRecommendProducts().then(dt => {
    //   if(dt.isOk) {
    //     console.log('updated...')
    //   }
    // })
  }

  const modalChange = useCallback(() => {
    setShowModal(!showModal)
    setFocusSearch(false)
  }, [showModal])

  const modalOpend = () => {
    setProducts(null)
    setFocusSearch(true)
    doSearch()
  }

  const handleSearchKeyPress = (event) => {
    const enterKeyPressed = event.keyCode === 13;
    if (enterKeyPressed) {
      event.preventDefault();
      doSearch();
    }
  }

  const handlePreSearchKeyPress = (event) => {
    const enterKeyPressed = event.keyCode === 13;
    if (enterKeyPressed) {
      event.preventDefault();
      if (preTitle !== "") {
        setSearchTitle(preTitle)
        setPreTitle("")
        setShowModal(true)
      }
    }
  }

  const doSearch = () => {
    if(searchTitle && searchTitle.trim() !== "") {
      searchProducts(searchTitle).then(dt => {
        if(dt.items && dt.items.length > 0) {
          for(let pt of dt.items) {
            pt.checked = false 
            for(let sku of pt.skus) {
              sku.checked = false 
            }
          }
          setProducts([])
          setProducts(dt.items)
        } else {
          setProducts([])
        }
      })
    } else {
      // setProducts([])
    }

  }

  const renderRecommendOperateView = () => {
    if(actionId > 0 && recommendProducts && recommendProducts.length > 0) {
      return (<div className={styles.operateView}>
        <Button onClick={updateProductsOnClick} >{t("upsell.edit.updateRecommendProducts")}</Button>
      </div>)
    }
  }

  //add recommend products
  const addProductsHandle = () => {
    // console.log(selectedProducts)
    getRecommendProductsInfo({products: selectedProducts}).then(dt => {
      if(dt.isOk) {
        setShowModal(false)
        const tempProducts = [...recommendProducts]
        dt.recommend_products.forEach(item => {
          const findItem = tempProducts.find(tp => tp.platformId === item.platformId)
          if(!findItem) {
            tempProducts.push(item)
          }
        })
        setRecommendProducts(tempProducts)
        onChange && onChange(tempProducts)
      }
    })
  }

  const productSelectedChg = (list) => {
    let products = []
    for(let pt of list) {
      if(pt.checked) {
        products.push({'productPlatformId': pt.platformId})
      }
    }
    setSelectedProducts(products)
    // console.log(products)
  }

  // const handleDisplayWaySelectChg = () => {
  //   handleUpsellAttrChange({...upsell, addToCartBkColor: v.hex})
  // }
  const onDeleteRecommendProduct = (productId) => {
    if(productId) {
      let tempProducts = [...recommendProducts]
      tempProducts = tempProducts.filter(item => item.platformId !== productId)
      setRecommendProducts(tempProducts)
      onChange && onChange(tempProducts)
    }
  }
  return (

    <Fragment>
      <Card title={t("upsell.edit.upsellRecommentPtsToUser")} sectioned>
                <FormLayout>
                  <FormLayout.Group >
                    <div onKeyDown={handlePreSearchKeyPress}>
                    <TextField label=""
                      type="search"
                      placeholder={t("upsell.edit.searchByProduct")}
                      inputMode="search"
                      value={preTitle}
                      prefix= <Icon source={SearchMinor} />
                      onChange={preTitleChange} />

                    </div>
                    
                  </FormLayout.Group>
                </FormLayout>
                <Modal
                  open={showModal}
                  onClose={modalChange}
                  title={t("upsell.edit.modalTitle")}
                  onTransitionEnd={modalOpend}
                  primaryAction={{
                    content: t("upsell.edit.primaryAddBtn"),
                    onAction: addProductsHandle,
                    disabled: selectedProducts.length === 0,
                  }}
                  secondaryActions={[
                    {
                      content: t("upsell.edit.secondCancelBtn"),
                      onAction: modalChange,
                    },
                  ]}
                >
              <Modal.Section>
                  <div onKeyDown={handleSearchKeyPress}>
                    <TextField label=""
                      type="search"
                      placeholder={t("upsell.edit.modalSearchPlaceHolder")}
                      inputMode="search"
                      value={searchTitle}
                      onChange={modalTitleChange}
                      focused={focusSearch}
                      autoFocus={true}
                      helpText =  {t("upsell.edit.modalSearchHelpText")}
                      prefix= <Icon source={SearchMinor} />
                      
                    />
                      
                  </div>
              </Modal.Section>
                <Scrollable shadow>
                  {
                    products && (products.length > 0 ? <ProductList dataSource={products} onCheckedChange={productSelectedChg}/> : <div className={styles.cannotFound}>{t("upsell.edit.noResultFound")} "<label>{searchTitle}</label>"</div>)
                  }
                </Scrollable>
                </Modal>
                <div>
                  <RecommendProductView products = {recommendProducts} onProductDelete= {onDeleteRecommendProduct} upsellInfo={upsell}/>
                </div>
                {recommendProducts && recommendProducts.length > 0 && <div>
                  <FormLayout>
                  <div className={styles.colorPanel}>
                    <label className={styles.customAttrs}>{t("upsell.edit.recommendProductsAttr")}</label>
                    <div className={styles.row}>
                    <TextField value={upsell.title} 
                      onChange={(v)=> { handleUpsellAttrChange({...upsell, title: v})}} 
                      label={t("upsell.edit.upsellTitle")}
                      type="text" 
                      placeholder={t("upsell.edit.fillTitlePlaceHolder")}
                    />
                    </div>
                    <div className={styles.row}>
                    <TextField value={upsell.addToCartText} 
                      onChange={(v)=> { handleUpsellAttrChange({...upsell, addToCartText: v})}} 
                      label={t("upsell.edit.addToCartText")}
                      type="text" 
                      placeholder={t("upsell.edit.fillAddToCartPlaceHolder")}
                    />
                    </div>

                    <div className={styles.row}>
                      <p>{t("upsell.edit.addToCartButtonTxtColor")}</p>
                      <LabelPickColor color={ upsell.addToCartTextColor } onChangeComplete={ (v)=> { handleUpsellAttrChange({...upsell, addToCartTextColor: v.hex})} }/>
                    </div>
                    <div className={styles.row}>
                      <p>{t("upsell.edit.addToCartButtonBkColor")} </p>
                      <LabelPickColor color={ upsell.addToCartBkColor } onChangeComplete={ (v)=> { handleUpsellAttrChange({...upsell, addToCartBkColor: v.hex})} }/>
                    </div>
                    <div className={styles.row}>
                      <p>{t("upsell.edit.displayWay")} </p>
                      <Select label="" options={upsellDisplayOptions} onChange={(v)=> { handleUpsellAttrChange({...upsell, displayMethod: v})}} value={upsell.displayMethod}  />
                    </div>
                  </div>
                  </FormLayout>
                </div>}
                <div>
                {
                  renderRecommendOperateView()
                }
                </div>
      </Card>
    </Fragment>

  )

}
export default withTranslation()(UpsellEdit)
