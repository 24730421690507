import request from "../utils/request"

export function searchProducts(queryKey, withSku) {
  return request.get(`api/shop/search_product?title=${queryKey}&with_sku=${withSku}`)
}

export function getAllCollections() {
  return request.get(`api/shop/get_collections`)
}

export function getCurrentUser() {
  return request.get(`api/currentUser`)
}

export function updateLanguageShow(code) {
  return request.post(`api/shop/update_show_language?language=${code}`)
}
