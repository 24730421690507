import React, { useState, useCallback } from "react"
import {  Scrollable, Modal, TextField, Icon } from '@shopify/polaris';
import { SearchMinor } from '@shopify/polaris-icons';
import styles from "./index.module.scss"
import ProductList from './product_list';
import SelectedList from "./selected_list";
import { searchProducts } from '../../api/shop'
import { withTranslation } from 'react-i18next';

const ProductSearch = ({searchLabelTxt, withSku, initSearchResultItems = [], searchResultChanged, t}) => {
  const [showModal, setShowModal] = useState(false)
  const [focusSearch, setFocusSearch] = useState(false)
  const [preTitle, setPreTitle] = useState("")
  const [searchTitle, setSearchTitle] = useState("")
  const [products, setProducts] = useState(null)
  const [selectedProducts, setSelectedProducts] = useState([])

  const convertedItems = (initSearchResultItems && initSearchResultItems.map((item) => ({'productPlatformId': item.sourceId, 'title': item.title, 'image': item.imageUrl,  'handle': item.handle}))) || []
  const [searchListTbProducts, setSearchListTbProducts] = useState(convertedItems)



  // useEffect(loadRecommendProducts, []);

  const preTitleChange = (title) => {
    setPreTitle(title)
  }
  const modalTitleChange =  (title) => {
    setSearchTitle(title)
  }
  const handlePreSearchKeyPress = (event) => {
    const enterKeyPressed = event.keyCode === 13;
    if (enterKeyPressed) {
      event.preventDefault();
      if (preTitle !== "") {
        setSearchTitle(preTitle)
        setPreTitle("")
        setShowModal(true)
      }
    }
  }
  const productSelectedChg = (list) => {
    let products = []
    for(let pt of list) {
      if(pt.checked) {
        // console.log(pt)
        products.push({'productPlatformId': pt.platformId, 'title': pt.title, 'image': pt.image, 'handle': pt.handle})
      }
    }
    setSelectedProducts(products)
    // console.log(products)
  }
  const modalChange = useCallback(() => {
    setShowModal(!showModal)
    setFocusSearch(false)
  }, [showModal])

  const modalOpend = () => {
    setProducts(null)
    setFocusSearch(true)
    doSearch()
  }

  const handleSearchKeyPress = (event) => {
    const enterKeyPressed = event.keyCode === 13;
    if (enterKeyPressed) {
      event.preventDefault();
      doSearch();
    }
  }

  const doSearch = () => {
    if(searchTitle && searchTitle.trim() !== "") {
      searchProducts(searchTitle, withSku).then(dt => {
        if(dt.items && dt.items.length > 0) {
          for(let pt of dt.items) {
            pt.checked = false 
            if(pt.skus && pt.skus.length > 0) {
              for(let sku of pt.skus) {
                sku.checked = false 
              } 
            }

          }
          setProducts([])
          setProducts(dt.items)
        } else {
          setProducts([])
        }
      })
    } else {
      // setProducts([])
    }

  }

  const addProductsHandle = () => {
    // console.log(selectedProducts)
    setShowModal(false)
    const tempList = [...searchListTbProducts]
    selectedProducts.forEach(item => {
      let isExisted = false
      searchListTbProducts.forEach(tp => {
        if(item.productPlatformId === tp.productPlatformId) {
          isExisted = true 
          return 
        }
      })
      if(!isExisted) {
        tempList.push(item)
      }
    })
    setSearchListTbProducts(tempList)
    if(searchResultChanged) {
      searchResultChanged(tempList)
    }
  }

  const searchTableListRemoveChg = (item) => {
    const pts = searchListTbProducts.filter((tp) => tp.productPlatformId !== item.productPlatformId)
    setSearchListTbProducts(pts)
    if(searchResultChanged) {
      searchResultChanged(pts)
    }
  }
  return (<div>
    <div onKeyDown={handlePreSearchKeyPress} className={styles.searchBox}>
      <TextField label={searchLabelTxt}
        type="search"
        placeholder={t("productSearch.searchPlaceHolder")}
        inputMode="search"
        value={preTitle}
        prefix= <Icon source={SearchMinor} />
        onChange={preTitleChange} />
      <Modal
        open={showModal}
        onClose={modalChange}
        title={t("productSearch.addProductTitle")}
        onTransitionEnd={modalOpend}
        primaryAction={{
          content: t("productSearch.add"),
          onAction: addProductsHandle,
          disabled: selectedProducts.length === 0,
        }}
        secondaryActions={[
          {
            content: t("productSearch.cancel"),
            onAction: modalChange,
          },
        ]}
      >
        <Modal.Section>
          <div onKeyDown={handleSearchKeyPress}>
            <TextField label=""
              type="search"
              placeholder={t("productSearch.searchPtPlaceHolder")}
              inputMode="search"
              value={searchTitle}
              onChange={modalTitleChange}
              focused={focusSearch}
              autoFocus={true}
              helpText =  {t("productSearch.searchHelpText")}
              prefix= <Icon source={SearchMinor} />
                      
            />
                      
          </div>
        </Modal.Section>
        <Scrollable shadow>
          {
            products && (products.length > 0 ? <ProductList dataSource={products} onCheckedChange={productSelectedChg}/> : <div className={styles.cannotFound}>{t("productSearch.noResultFor")} "<label>{searchTitle}</label>"</div>)
          }
        </Scrollable>
      </Modal>
      <div>
        <SelectedList products={searchListTbProducts} onRemoveItem={searchTableListRemoveChg}/>
      </div>
    </div>
  </div>)
}
export default withTranslation()(ProductSearch)
