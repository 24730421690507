import React from "react"
import { Checkbox } from '@shopify/polaris';
import styles from "./product_list.module.scss"
import { withTranslation } from 'react-i18next';

class ProductList extends React.Component {
  constructor() {
    super()
    this.state = {
      products: []
    }
  }

  componentDidMount() {
    this.setState({products: this.props.dataSource})
  }

  processProductChanged (item,val) {
    const list = [...this.state.products]
    for(let product of list) {
      if(product.platformId === item.platformId) {
        product.checked = val
        for(let sku of product.skus) {
          sku.checked = val 
        }
        break
      }
    }
    this.setState({products: list})

    this.props.onCheckedChange && this.props.onCheckedChange(list)

  }

  processSkuChanged (item,val) {
    const list = [...this.state.products]
    for(let product of list) {
      let isFound = false
      for(let sku of product.skus) {
        if(sku.platformId === item.platformId) {
          sku.checked = val 
          isFound = true
          break 
        }
      }
      if(product.skus.length > 1) {
        if(this.calculateCheckedCount(product.skus, val) === product.skus.length) {
          product.checked = val
        } else {
          product.checked = 'indeterminate'
        }
      }
      if(isFound) {
        break
      }

    }
    this.setState({products: list})
    this.props.onCheckedChange && this.props.onCheckedChange(list)
  }

  calculateCheckedCount (items, checked) {
    let k = 0
    for(let item of items) {
      if(item.checked === checked) {
        k++
      }
    }
    return k
  }
  onClickRow (item, isSku) {
    // console.log(item, isSku)
    if(isSku) {
      this.processSkuChanged(item, !item.checked)
    } else {
      this.processProductChanged(item, !item.checked)
    }
  }
  render () {
    const { t } = this.props
    return (
      <div className={styles.productList}>
        <table>
          <tbody>
            {
              this.state.products.map((pt) => {
                let inventoryDesc = ""
                if(pt.skus.length > 0) {
                  let k = 0
                  for(let sku of pt.skus) {
                    k = k + sku.inventoryQuantity
                  }
                  inventoryDesc = `${k} ${t("upsell.productList.inStockFor")} ${pt.skus.length} ${t("upsell.productList.inStockFor")}`
                }

                return <tr key={pt.platformId} onClick={() => { this.onClickRow(pt, false) }}>
                <td className={styles.rowHeader}><Checkbox  label="" checked={pt.checked} /></td>
                <td><img src={pt.image} alt={pt.title} /></td>
                <td width="60%">{pt.title}</td>
                {/* <td>{pt.status}</td> */}
                <td>{inventoryDesc}</td>
                <td>{pt.productType}</td>
                </tr>
              })
            }
          </tbody>
        </table>
      </div>
    )
  }
}
export default withTranslation()(ProductList)
