import React, { Fragment, useState } from "react"
import {Card,  FormLayout, TextField, Select, Layout, Checkbox} from '@shopify/polaris';
import CouponPreview from "./couponPreview";
import LabelPickColor from  "../labelColorPicker"
import styles from './couponEdit.module.scss';
import { Trans, withTranslation } from 'react-i18next';
import HowToGetImage from "../howToGetImage";

const CouponEdit = ({couponInfo = {}, onChange, onError, t}) => {

  const [coupon, setCoupon] = useState(couponInfo)
  const [validation, setValidation] = useState({
    discountValErr: false,
    couponTitleErr: false,
    couponGotBtnErr: false,

  })

  const couponTypeOptions = [
    {label: t("couponEdit.percentageOption"), value: '0'},
    {label: t("couponEdit.fixedAmountOpton"), value: '1'}
  ]
  const currencySymbolOptions = [
    {label: '$', value: '$'},
    {label: '€', value: '€'},
    {label: '£', value: '£'},
    {label: '¥', value: '¥'},
    {label: '฿', value: '฿'},
    {label: '₩', value: '₩'},
    {label: 'R$', value: 'R$'},
    {label: '₨', value: '₨'},
    {label: '₽', value: '₽'},
  ]
  const discountValSuffix = coupon.type === 0 ? "% OFF" : ""

  const getValueTypeHelpText = () => {
    const tp = coupon.type.toString()
    switch(tp) {
      case '0':
        return  (<span><Trans i18nKey="couponEdit.percentage">
        The value type of the coupon price rule. <strong>Percentage: </strong>Applies a percentage discount of value.
        </Trans></span>)
      case '1':
        return  (<span><Trans i18nKey="couponEdit.amount">
          The value type of the coupon price rule. <strong>Fixed Amount: </strong>Applies a discount of value as a unit of the store's currency.
        </Trans></span>)
      default:
        return ""
    }
  }
  const supportEmoji = <span>{t("couponEdit.canFill")} <a href="https://getemoji.com/" target="_blank" rel="noopener noreferrer">emoji</a></span>


  const handleCouponAttrChange = (newValue) => {
    setCoupon(newValue)
    onChange && onChange(newValue)
  }

  const onDiscountValMoved =  () => {
    if(coupon.discountValue === "") {
      setValidation({...validation,  discountValErr: true})
      onError && onError(true)
    } else {
      setValidation({...validation,  discountValErr: false})
    }
    
  }
  const onCouponTitleMoved =  () => {
    if(coupon.title === "") {
      setValidation({...validation,  couponTitleErr: true})
      onError && onError(true)
    } else {
      setValidation({...validation,  couponTitleErr: false})
    }
  }
  const onCouponGotBtnTxtMoved = () => {
    if(coupon.title === "") {
      setValidation({...validation,  couponGotBtnErr: true})
      onError && onError(true)
    } else {
      setValidation({...validation,  couponGotBtnErr: false})
    }
  }
  return (
    <Fragment>
      <Card title={t("couponEdit.title")} sectioned> 
        <FormLayout>
          <FormLayout.Group>
            <Select label={t("couponEdit.valueType")} options={couponTypeOptions} onChange={(v)=> { handleCouponAttrChange({...coupon, type: v})}} value={coupon.type}  helpText={getValueTypeHelpText()}/>
            {(coupon.type === "1" || coupon.type === 1) &&
              <Select label={t("couponEdit.currencySymbol")} options={currencySymbolOptions} onChange={(v)=>{handleCouponAttrChange({...coupon, currencySymbol: v})}} value={coupon.currencySymbol} />
            }
            <TextField value={coupon.discountValue} 
                onChange={(v)=> { handleCouponAttrChange({...coupon, discountValue: v})}} 
                label={t("couponEdit.discountValue")} 
                type="number" 
                placeholder="1-99"
                min="1"
                max="99"
                suffix={discountValSuffix}
                onBlur={onDiscountValMoved}
                error={validation.discountValErr ? t("couponEdit.discountValueRequired") : ""}
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <TextField value={coupon.title} 
                  onChange={(v)=> { handleCouponAttrChange({...coupon, title: v})}} 
                  label={t("couponEdit.couponTitle")}  
                  type="text"
                  placeholder=""
                  min="0"
                  max="80"
                  onBlur={onCouponTitleMoved}
                  error={validation.couponTitleErr ? t("couponEdit.couponTitleRequired") : ""}
                  helpText={supportEmoji}
            />
            <TextField value={coupon.subTitle} 
                  onChange={(v)=> { handleCouponAttrChange({...coupon, subTitle: v})}} 
                  label={t("couponEdit.couponSubTitle")} 
                  type="text" placeholder=""
                  multiline={3}
                  min="0"
                  max="80"
                  helpText={supportEmoji}
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <TextField value={coupon.gotButtonText} 
                  onChange={(v)=> { handleCouponAttrChange({...coupon, gotButtonText: v})}} 
                  label={t("couponEdit.gotCouponButtonText")} 
                  type="text" 
                  placeholder=""
                  onBlur={onCouponGotBtnTxtMoved}
                  error={validation.couponGotBtnErr ? t("couponEdit.gotCouponButtonTextRequired") : ""}
                  helpText={
                    <span></span>
                  }
            />
            <TextField value={coupon.expirationHours} 
                  onChange={(v)=> { handleCouponAttrChange({...coupon, expirationHours: v})}} 
                  label={t("couponEdit.pageExpirationHoursText")} 
                  type="number" 
                  placeholder="1-500"
                  min="1"
                  max="500"
                  helpText={
                    <span>{t("couponEdit.pageExpirationHoursHelpText")}</span>
                  }
            /> 
          </FormLayout.Group>
          <FormLayout.Group>
            <TextField value={coupon.applyRemindText} 
                  onChange={(v)=> { handleCouponAttrChange({...coupon, applyRemindText: v})}} 
                  label={t("couponEdit.couponApplyReminderTextOpt")}  
                  type="text" placeholder=""
                  helpText={
                    <span>{t("couponEdit.couponApplyReminderHelpTxt")}</span>
                  }
            />  
            <TextField value={coupon.pageRemindText} 
                  onChange={(v)=> { handleCouponAttrChange({...coupon, pageRemindText: v})}} 
                  label={t("couponEdit.pageReminderText")} 
                  type="text" placeholder=""
                  helpText={
                    <span>{t("couponEdit.pageReminderHelpTxt")}</span>
                  }
            /> 

          </FormLayout.Group>
        </FormLayout>
        <div className={styles.ps}>{t("couponEdit.couponTips")}</div>          
      </Card>
      <Card title={t("edit.previewTitle")} sectioned>
        <Layout>
          <Layout.Section>
            <CouponPreview data={coupon} />
          </Layout.Section>
          <Layout.Section secondary>
            <div className={styles.colorPanel}>
              <label className={styles.customAttrs}>{t("couponEdit.couponCustomAttribute")}</label>
              <div>
                  <Checkbox label={t("couponEdit.needLeftEmail")} checked={ coupon.needLeftEmail } onChange={(v)=> { handleCouponAttrChange({...coupon, needLeftEmail: v})}}/>
                  {
                    coupon.needLeftEmail && <TextField value={coupon.needEmailRemindText} 
                    onChange={(v)=> { handleCouponAttrChange({...coupon, needEmailRemindText: v})}} 
                    label={t("couponEdit.needLeftEmailReminder")} 
                    type="text" 
                    placeholder={t("couponEdit.needLeftEmailPlaceHolder")}
                    />
                  }
              </div>
              <div className={styles.row}>
                  <TextField value={coupon.bgImage} 
                    onChange={(v)=> { handleCouponAttrChange({...coupon, bgImage: v})}} 
                    label={t("couponEdit.backgroundImage")} 
                    type="text" 
                    multiline={2}
                    placeholder={t("couponEdit.backgroundImgPlaceHolder")}
                  />
              </div>
              <div className={styles.png}>{t("edit.pngImageDesc")}</div>
              <div className={styles.row}>
                <HowToGetImage />
              </div>
              <div className={styles.row}>
                <p>{t("couponEdit.backgroundColor")}</p>
                  <LabelPickColor color={ coupon.bgColor } onChangeComplete={ (v)=> { handleCouponAttrChange({...coupon, bgColor: v.hex})} }/>
              </div>

              <div className={styles.row}>
                  <p>{t("couponEdit.textColor")}</p>
                  <LabelPickColor color={ coupon.txtColor } onChangeComplete={ (v)=> { handleCouponAttrChange({...coupon, txtColor: v.hex})} }/>
              </div>
              <div className={styles.row}>
                  <p>{t("couponEdit.buttonBackgroundColor")}</p>
                  <LabelPickColor color={ coupon.btnBgColor } onChangeComplete={ (v)=> { handleCouponAttrChange({...coupon, btnBgColor: v.hex})} }/>
              </div>
              <div className={styles.row}>
                  <p>{t("couponEdit.buttonTextColor")}</p>
                  <LabelPickColor color={ coupon.btnTextColor } onChangeComplete={ (v)=> { handleCouponAttrChange({...coupon, btnTextColor: v.hex})} }/>
              </div>

            </div>
          </Layout.Section>
        </Layout>
      </Card>
    </Fragment>
  )
}


export default withTranslation()(CouponEdit);
