export default {
  translation: {
    app: {
      action: "弹窗",
      settings: "设置",
      suggestion: "问题与建议",
      collectionReorder: "集合页重排(Beta)",
      contactUs: "如果您有任何问题或建议，请联系我们",
      help: "帮助?",
      submmitIssues: "提交问题",
      leaveComments: "留下评论",
    },
    couponEdit: {
      title: "自动折扣 - 优惠券",
      percentageOption: "百分比",
      fixedAmountOpton: "固定金额",
      percentage: "优惠券价格规则，<strong>百分比：</strong>应用订单总金额的百分比折扣。",
      amount: "优惠券价格规则，<strong>固定金额：</strong>以商店货币为单位应用固定金额折扣。",
      canFill: "可以填写",
      valueType: "值类型",
      currencySymbol: "货币符号",
      discountValue: "折扣额度",
      discountValueRequired: "折扣额度是必填项",
      couponTitle: "优惠券标题",
      couponTitleRequired: "优惠券标题为必填项",
      couponSubTitle: "优惠券副标题（选填）",
      gotCouponButtonText: "获得优惠券按钮文本",
      gotCouponButtonTextRequired: "必须填写获得优惠券按钮文本",
      couponApplyReminderTextOpt: "优惠券应用提醒文本（选填）",
      couponApplyReminderHelpTxt: "提醒用户折扣将在结账时自动应用。",
      pageReminderText: "页面提醒文本（选填）",
      pageReminderHelpTxt: "用户领取优惠券后，页面底部会显示提醒文字。如果留空，则不会显示提醒文本。",
      couponCustomAttribute: "<<优惠券定制属性>>",
      backgroundImage: "背景图片（选填）：",
      backgroundImgPlaceHolder: "填写图片网址",
      backgroundColor: "背景颜色：",
      textColor: "文字颜色：",
      buttonBackgroundColor: "按钮背景颜色：",
      buttonBorderColor: "按钮边框颜色：",
      buttonTextColor: "按钮文字颜色：",
      needLeftEmail: "需要客户留下电子邮件才能获得优惠券并成为订阅者",
      needLeftEmailReminder: "提醒文字",
      needLeftEmailPlaceHolder: "请输入提醒文字",
      pageExpirationHoursText: "优惠券持续时间",
      pageExpirationHoursHelpText: "用户获得优惠券后，优惠券的持续时间(小时数)",
      couponTips: "提示：自动折扣的优点是用户无需手动输入折扣码即可自动应用折扣。",
    },
    edit: {
      previewTitle: '预览',
      emailReminderText: "只需留下您的电子邮件",
      leavePageOptionText: "离开当前页面（点击返回键或手指扫一扫）",
      stayOnPageOptionText: "在页面上停留几秒钟",
      scrollBottomOptionText: "滚动到页面底部",
      addToCartOptionText: "点击【加入购物车】按钮",
      addDoNothingText: "什么都不做(立刻触发)",
      pngImageDesc: "建议使用带有透明背景的PNG图像可以获得最佳效果。",
      howToGetImageAddress: "如何获取图片的URL地址？",
      coupon: {
        title: "Congratulations!",
        subTitle: "You are very lucky\nwon a COUPON",
        gotButtonText: "Got it",
        applyRemindText: "*It will be applied automatically at checkout*",
        pageRemindText: "You have an unused coupon, checkout now",
      },
      survey: {
        title: "Meet Issues?",
        subTitle: "Is there anything preventing you from purchase this product?",
        otherText: "Other (please specify)",
        submitText: "Submit",
        option1: "Option1",
        option2: "Option2",
      },
      upsell: {
        title: "Hot Products",
        addToCartText: "Add to Cart",
      },
      cart: {
        fireAtLeastOneItem: "购物车中至少有 1 件商品",
      },
      alert: {
        pleaseSelectProducts: "请选择产品。",
        pleaseSelectCollections: "请选择集合页。",
      },
      comeIn: {
        anyProductDetailPage: "任意产品详情页（landing page）",
        anyCollectionPage: "任意集合页（collections page）",
        shoppingCart: "购物车页面",
        specialProductDetailPage: "某些产品详情页",
        specialCollectionPage: "某些集合页",
        homePage: "网站首页",
        paySuccessedPage: "支付成功页（ThankYou 页面）",
      },
      doAction: {
        popUpCoupon: "弹出优惠券（应用于整个订单）",
        popUpSurvey: "弹出调查问卷",
        recommendProducts: "推荐产品（追加销售）",
        popUpSubscribe: "弹出订阅窗口",
      },
      buttonText: {
        editAction: "编辑弹窗",
        addAction: "添加弹窗",
        update: "更新",
        save: "保存",
      },
      page: {
        actions: "弹窗",
      },
      actionAttr: {
        name: "名称",
        namePlaceHolder: "弹窗名称",
        nameError: "弹窗名称为必填项",
        userComesIn: "当用户进来时",
        userDoseAction: "用户执行此操作",
        staySeconds: "停留时长(秒)",
        stayPlaceHolder: "1-99",
        stayError: "需要停留秒数且必须大于零",
        thenTriggerAction: "然后触发动作",
        leavePageDesc: "这里的【离开当前页面】是指用户点击浏览器的返回按钮，或者在手机上，用户滑动手指关闭浏览器。",
        selectSpecialProduct: "选择某些产品",
        cartTriggerCondition: "购物车触发条件",
        triggerOnDevice: "触发设备限制",
        allDevice: "所有设备",
        mobile: "移动端设备",
        pc: "PC",
        enableURLRestrict: "仅在页面URL包含如下字符时才触发, 比如 utm_source=facebook",
        urlPatternPlaceholder: "输入页面URL的一部分",
        urlPatternError: "请输入页面URL的一部分",
      }
    },
    list: {
      bannerTitle: "创建弹窗之前，请务必按照【设置】菜单中的步骤进行必要的配置",
      bannerReminder: "如果您已经设置，请忽略此消息。",
      emptyHeading: "管理您的弹窗",
      emptyAction: "添加弹窗",
      emptyActiveAction: "没有激活的弹窗。您可在 [未激活] 列表中激活任何弹窗。",
      comeIn: {
        anyProductPage: "进入任何产品页面",
        anyCollectionPage: "进入任何集合页面",
        shoppingCart: "进入购物车",
        specialProductDetailPage: "进入某些产品页面",
        specialCollectionPage: "进入某些集合页面",
        homePage: "进入首页",
        paySuccessedPage: "进入支付成功页面",
      },
      doAction: {
        leavePage: "当用户离开时",
        staySeconds: "当用户停留 {{seconds}} 秒",
        scrollToBottom: "当用户滚动到页面底部时",
        clickAddToCart: "当用户单击添加到购物车按钮时",
        doNothing: "什么都不做(立刻)",
      },
      fireAction: {
        popUpCoupon: "弹出优惠券",
        popUpSurvey: "弹出调查问卷",
        recommendProducts: "推荐产品（追加销售）",
      },
      device: {
        all: "所有设备",
        mobile: "移动端设备",
        pc: "PC",
      },
      noneSubscribers: "没有订阅用户",
      alert: {
        areYouDelete: "您确定删除此弹窗吗？",
        areYouActive: "您确定激活此弹窗吗？",
        areYouDisable: "您确定禁用此弹窗吗？",
      },
      buildTable: {
        discount: "折扣",
        delete: "删除",
        active: "激活",
        edit: "编辑",
      },
      tableList: {
        name: "名称",
        userActionFlow: "用户操作流程",
        createTime: "创建时间",
      },
      stCouponUi: {
        trigger: "触发",
        sessions: "次",
        gotCoupon: "获得优惠券",
        closeCoupon: "关闭优惠券",
        placeOrder: "使用优惠券下单",
        totalAmountOfOrders: "订单总额",
      },
      stSurveyUi: {
        trigger: "触发",
        sessions: "次",
        submitSurvey: "提交调查",
        closeSurvey: "关闭调查",
        optionAnswers: "选项答案",
        otherAnswers: "其他答案",
      },
      stRecommendPtUi: {
        trigger: "触发",
        sessions: "次",
        addToCart: "添加到购物车",
        add: "添加",
        times: "次数",
      },
      hideAction: {
        hideCoupon: "隐藏优惠券预览",
        hideSurvey: "隐藏调查问卷预览",
        hideRecommendPt: "隐藏推荐产品预览",
      },
      previewAction: {
        previewCoupon: "预览优惠券",
        previewSurvey: "预览调查问卷",
        previewRecommendPt: "预览推荐产品",
      },
      itemForm: {
        editAction: "编辑弹窗",
        hideStatistic: "隐藏统计信息",
        showStatistic: "显示统计数据",
        active: "激活",
        disable: "禁用",
        userActionFlow: "用户操作流程",
        couponDiscount: "优惠券折扣",
        status: "状态",
        unActive: "未激活",
        online: "ONLINE",
        triggerOn: "触发设备",
        needLeftEmail: "需要留下邮箱",
        createTime: "创建时间",
        couponPreview: "优惠券预览",
        surveyPreview: "调查问卷预览",
        recommendPtPreview: "推荐产品预览",
        statistics: "统计数据",
        enableURLRestrict: "触发URL限制",
        urlPatternDesc: "页面URL包含如下字符时才触发",
      },
      freeplan: {
        title: "您当前使用的是免费 Plan",
        actionLimit: "您最多只能添加 {{maxActions}} 个弹窗",
        feature1: "所有弹窗, (包括优惠券、调查问卷), 都支持用户定制化",
        feature2: "可以自定义弹出时机和策略",
        feature3: "可以获取数据分析",
        changePlan: "升级 Plan",
      },
      basicPlan: {
        changePlan: "升级 Plan",
        basicPlan: "基础 Plan",
        eachMonthFee: "5.99 美元/月",
        sevenDaysFree: "7 天免费试用",
        feature1: "无限弹窗",
        feature2: "所有弹窗, (包括优惠券、调查问卷), 都支持用户定制化",
        feature3: "触发弹窗时向客户推荐产品（追加销售）",
        feature4: "可以自定义弹出时机和策略",
        feature5: "可以获取数据分析",
        upgrade: "升级",
        upgrading: "升级...",
      },
      rt: {
        actions: "弹窗",
        addAction: "添加弹窗",
        productDetailPage: "产品详情页",
        collectionPage: "集合页",
        shoppingCartPage: "购物车页面",
        homePage: "首页",
        paySuccessedPage: "支付成功页",
        unactiveActionList: "[未激活] 弹窗列表",
      }

    },
    cartCondition: {
      cartValue: "购物车总金额",
      cartQuantity: "购物车SKU数量",
      lessThan: "少于",
      greaterTo: "大于",
      totalPriceHolder: "总价",
      add: "添加",
    },
    collectionList: {
      title: "集合页列表",
    },
    issueSuggestion: {
      successMessage: "您的留言我们已收到，谢谢！",
      plsTellUs: "如果您有任何问题，请告诉我们✍️",
      issue: "问题",
      suggestion: "建议",
      atLeastTenWords: "至少10个字。",
      submit: "提交",
    },
    productSearch: {
      searchPlaceHolder: "输入产品名称, 然后敲击 Enter 键 进行搜索",
      addProductTitle: "添加产品",
      add: "添加",
      cancel: "取消",
      searchPtPlaceHolder: "搜索产品",
      searchHelpText: "输入产品名称, 然后敲击 Enter 键 进行搜索",
      noResultFor: "未找到任何数据",
    },
    productList: {
      inStockFor: "有货",
      variants: "变参",
    },
    selectedList: {
      remove: "移除",
    },
    settings: {
      copySuccessed: "复制到剪贴板成功",
      installSpec: "安装说明",
      inOrderTo: "如需获取优惠券带来的订单统计信息，请按照以下步骤进行设置。",
      list1: "登录店铺管理面板，点击左下角的 [设置]。",
      list2: "点击左侧的 [结账] 菜单项。",
      list3: "向下滚动到 [附加脚本] 设置项。",
      list4: "将以下模板脚本粘贴到 [订单状态页] 文本框中。",
      list5: "点击 [保存] 按钮保存设置。",
      templateScript: "模板脚本",
      clicToCopy: "点击复制",
      explanationLegd: "操作图例说明",
    },
    surveyEdit: {
      caption: "调查问卷",
      removeButton: "移除",
      title: "调查问卷标题",
      titleError: "调查问卷标题为必填项",
      subTitle: "调查问卷副标题（选填）",
      otherOptionName: "其他选项名称",
      otherOptionError: "需要填写其他选项名称",
      submitButton: "提交按钮文本",
      submitButtonError: "提交按钮文本为必填项",
      fillSurveyPlaceHolder: "在此处填写调查问卷选项",
      fillSurveyHelpText: "你最多能添加的选项数为：",
      addOption: "添加选项",
      surveyCustomAttr: "<<用户调查问卷定制属性>>",
      bkGroundImgOpt: "背景图片（选填）：",
      fillImgUrl: "填写图片网址",
      bkGroundColor: "背景颜色：",
      textColor: "文字颜色：",
      buttonBkGroundColor: "按钮背景颜色：",
      buttonBorderColor: "按钮边框颜色：",
      hideCloseButton: "隐藏用户调查关闭按钮",
      buttonTextColor: "按钮文字颜色：",
      canGetCouponText: "如果用户完成这份调查问卷，他们能获得优惠券吗？ ",
      couponCode: "优惠码",
      couponCodePlaceholder: "填写给用户的优惠码",
      getCouponTips: "获得优惠券时，显示的提示信息",
      getCouponTipsPlaceholder: "填写获得优惠券的提示信息",
      getCouponGuid1: "您可以点击这里的链接 ",
      getCouponGuid2: " 创建一张优惠券，然后将获得的优惠券编码填写到上面的文本框内。",
    },
    subscribeEdit: {
      cardTitle: "订阅",
      title: "标题",
      titleError: "订阅标题是必填项",
      subTitle: "副标题（可选）",
      unSubDesc: "取消订阅说明",
      unSubDescError: "取消订阅说明是必填项",
      
      placeHolderText: "电子邮件填写提示",
      placeHolderError: "电子邮件填写提示是必填项",
      btnText: "订阅按钮文本",
      btnTextError: "订阅按钮文本是必填项",
      
      cardPreview: "预览",
      subscribeCustomAttr: "<<订阅自定义属性>>",
      bkGroundColor: "背景颜色：",
      textColor: "文字颜色：",
      buttonBkGroundColor: "按钮背景颜色：",
      buttonTextColor: "按钮文字颜色：",
    },
    upsell: {
      productList: {
        inStockFor: "有货",
        variants: "变参",
      },
      recommendPts: {
        deleteAlert: "您确定要删除此产品吗？",
      },
      edit: {
        updateRecommendProducts: "更新推荐产品信息",
        upsellRecommentPtsToUser: "追加销售(UpSell) - 向用户推荐产品",
        searchByProduct: "输入产品名称, 然后敲击 Enter 键 进行搜索",
        modalTitle: "添加产品",
        primaryAddBtn: "添加",
        secondCancelBtn: "取消",
        modalSearchPlaceHolder: "搜索产品",
        modalSearchHelpText: "输入产品名称, 然后敲击 Enter 键 进行搜索",
        noResultFound: "未找到任何数据",
        recommendProductsAttr: "<<推荐产品定制属性>>",
        upsellTitle: "追加销售标题：",
        fillTitlePlaceHolder: "填写标题",
        addToCartText: "添加到购物车文本：",
        fillAddToCartPlaceHolder: "填写添加到购物车文本",
        addToCartButtonTxtColor: "添加到购物车按钮文字颜色：",
        addToCartButtonBkColor: "添加到购物车按钮背景颜色：",
        displayWay: "显示方式",
        displayPopUpOpt: "Pop-Up 弹窗",
        displayBelowATCBtnOpt: "显示在 [Add to Cart] 按钮下面",
        displayInlineThankYouPageOpt: "内嵌到支付成功页面（Thank You 页面）",
      }
    },
    howToGetImage: {
      title: "获取图片URL地址指南",
      one: "打开含有图片的任何网页（你的店铺网站可能是最佳选择）。",
      two: "将鼠标光标置于图片上，并点击鼠标右键。",
      three: '点击 “复制图片地址” 菜单项，并将其粘贴到 背景图片 文本框中即可。',
    },
    leaveComments: {
      content: "可以给我们的APP留下评论吗？非常感谢",
      clickHere: "点击这里",
    },
    httpRequest: {
      authFailed: "会话已经失效，程序会自动刷新页面。",
    },
    collectionReorder: {
      title: "集合页重排 - 功能介绍",
      what: "什么是集合页重排？",
      whatdesc: "所谓集合页重排，就是你可以任意调整集合页内产品的顺序，而不会影响原始集合页产品的顺序，对于同一个集合页，你可以创建多个排序，不同的排序会生成不同的URL，便于广告投放，并进一步比较不同排序的广告效果。",
    }
  }
}
