import i18n from 'i18next'
// import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'
import EnUsTranslate from './locales/en'
import DETranslate from './locales/de'
import ESTranslate from './locales/es'
import JATranslate from './locales/ja'
import ZHTranslate from './locales/zh'

i18n
  .use(initReactI18next)
  .init({
    // lng: lang,
    resources: {
      en: EnUsTranslate,
      de: DETranslate,
      es: ESTranslate,
      ja: JATranslate,
      zh: ZHTranslate
    },
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'production' ? false : true,
    interpolation: {
      escapeValue: false,
      formatSeparator: ','
    },
  })

export default i18n