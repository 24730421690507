import React, {useContext} from "react"
import {Button} from '@shopify/polaris';
import styles from "./selected_list.module.scss"
import { AppContext } from "../../context-manager";
import { withTranslation } from 'react-i18next';

const SelectedList = ({products = [], onRemoveItem, t}) => {

  const { shopInfo } = useContext(AppContext)

  const tableList = products.map((item) => {
    return (<tr key={item.productPlatformId.toString()}>
      <td width="35px"><img src={item.image} alt={item.title} /></td>
      <td><a href={`https://${shopInfo.domain}/products/${item.handle}`} target="_blank" rel="noopener noreferrer">{item.title}</a></td>
      {onRemoveItem && <td><Button plain="true" onClick={() => { onRemoveItem(item) }}>{t("selectedList.remove")}</Button></td> }
    </tr>)
  })

  return (
    <div className={styles.selectedBox}>
      <table className={styles.tableList}>
        <tbody>
        {tableList}
        </tbody>
      </table>
    </div>
  )
}
export default withTranslation()(SelectedList)
