export default {
  translation: {
    app: {
      action: "ポップアップ",
      settings: "設定",
      suggestion: "質問と提案",
      contactUs: "ご質問やご提案がございましたら、お問い合わせください"
    },
    couponEdit: {
      title: "クーポン",
      percentageOption: "パーセンテージ",
      fixedAmountOpton: "定額",
      percentage: "クーポン価格ルール、<strong>パーセンテージ：</strong>注文の合計金額にパーセンテージ割引を適用します。",
      amount: "クーポン価格ルール、<strong>定額：</strong>定額割引が店舗通貨で適用されます。",
      canFill: "記入できます",
      valueType: "値のタイプ",
      currencySymbol: "通貨記号",
      discountValue: "割引額",
      discountValueRequired: "割引額が必要です",
      couponTitle: "クーポンタイトル",
      couponTitleRequired: "クーポンタイトルが必要です",
      couponSubTitle: "クーポン字幕（オプション）",
      gotCouponButtonText: "クーポンボタンのテキストを取得",
      gotCouponButtonTextRequired: "[クーポンを取得]ボタンのテキストを入力する必要があります",
      couponApplyReminderTextOpt: "クーポンアプリケーションのリマインダーテキスト（オプション）",
      couponApplyReminderHelpTxt: "割引はチェックアウト時に自動的に適用されることをユーザーに通知します。",
      pageReminderText: "ページリマインダーテキスト（オプション）",
      pageReminderHelpTxt: "ユーザーがクーポンを受け取ると、ページの下部にリマインダーテキストが表示されます。空白のままにすると、リマインダーテキストは表示されません。",
      couponCustomAttribute: "<<クーポンカスタム属性>>",
      backgroundImage: "背景画像（オプション）：",
      backgroundImgPlaceHolder: "画像のURLを入力します",
      backgroundColor: "背景色：",
      textColor: "テキストの色：",
      buttonBackgroundColor: "ボタンの背景色：",
      buttonBorderColor: "ボタンの境界線の色：",
      needLeftEmail: "クーポンを取得して購読者になるには、メールを残す必要があります",
      needLeftEmailReminder: "リマインダーテキスト",
      needLeftEmailPlaceHolder: "リマインダーテキストを入力してください",

    },
    edit: {
      emailReminderText: "メールを残すだけ",
      leavePageOptionText: "現在のページを離れます（戻るボタンをクリックするか、指をスワイプします）",
      stayOnPageOptionText: "数秒間ページにとどまります",
      scrollBottomOptionText: "ページの一番下までスクロールします",
      addToCartOptionText: "[カートに追加]ボタンをクリックします",
      addDoNothingText: "何もしない（すぐにトリガーする）",
      coupon: {
        title: "おめでとう！",
        subTitle: "あなたはとても幸運です\nクーポンを獲得しました",
        gotButtonText: "とった",
        applyRemindText: "*チェックアウト時に自動的に適用されます*",
        pageRemindText: "未使用のクーポンがあります。今すぐ使用してください",
      },
      survey: {
        title: "問題に対応しますか？",
        subTitle: "この商品の購入を妨げるものはありますか？",
        otherText: "その他（具体的にご記入ください）",
        submitText: "送信",
        option1: "オプション1",
        option2: "オプション2",
      },
      upsell: {
        title: "ホット製品",
        addToCartText: "カートに追加",
      },
      cart: {
        fireAtLeastOneItem: "ショッピングカートに少なくとも1つのアイテムがあります",
      },
      alert: {
        pleaseSelectProducts: "商品をお選びください。",
        pleaseSelectCollections: "コレクションページを選択してください。",
      },
      comeIn: {
        anyProductDetailPage: "商品詳細ページ（ランディングページ）",
        anyCollectionPage: "任意のコレクションページ（コレクションページ）",
        shoppingCart: "ショッピングカートページ",
        specialProductDetailPage: "特定の製品詳細ページ",
        specialCollectionPage: "特定のコレクションページ",
        homePage: "ホームページ",
        paySuccessedPage: "お支払い成功ページ（ThankYouページ）",
      },
      doAction: {
        popUpCoupon: "ポップアップクーポン（注文全体に適用）",
        popUpSurvey: "ポップアップアンケート",
        recommendProducts: "おすすめ商品（アップセル）",
        popUpSubscribe: "ポップアップサブスクリプションウィンドウ",
      },
      buttonText: {
        editAction: "ポップアップの編集",
        addAction: "ポップアップを追加",
        update: "更新する",
        save: "保存",
      },
      page: {
        actions: "ポップアップ",
      },
      actionAttr: {
        name: "名前",
        namePlaceHolder: "ポップアップ名",
        nameError: "ポップアップウィンドウの名前が必要です",
        userComesIn: "ユーザーが入ったとき",
        userDoseAction: "ユーザーはこれを行います",
        staySeconds: "滞在時間（秒）",
        stayPlaceHolder: "1-99",
        stayError: "秒数の間滞在する必要があり、ゼロより大きくなければなりません",
        thenTriggerAction: "次に、アクションをトリガーします",
        leavePageDesc: "ここで、[現在のページを離れる]とは、ユーザーがブラウザの戻るボタンをクリックするか、携帯電話でユーザーが指をスライドさせてブラウザを閉じることを意味します。",
        selectSpecialProduct: "特定の製品を選択する",
        cartTriggerCondition: "ショッピングカートのトリガー条件",
        triggerOnDevice: "デバイス制限をトリガーする",
        allDevice: "すべての機器",
        mobile: "モバイル機器",
        pc: "PC",

      }
    },
    list: {
      bannerTitle: "ポップアップウィンドウを作成する前に、必ず[設定]メニューの手順に従って必要な設定を行ってください。",
      bannerReminder: "すでに設定している場合は、このメッセージを無視してください。",
      emptyHeading: "ボタンをクリックしてポップアップを作成します",
      emptyAction: "ポップアップを追加",
      emptyActiveAction: "アクティブなポップアップウィンドウはありません。 [非アクティブ]リストの任意のポップアップウィンドウをアクティブにできます。",
      comeIn: {
        anyProductPage: "製品ページに移動します",
        anyCollectionPage: "コレクションページに移動します",
        shoppingCart: "ショッピングカートに入る",
        specialProductDetailPage: "いくつかの製品ページに移動します",
        specialCollectionPage: "いくつかのコレクションページに移動します",
        homePage: "メインページに入る",
        paySuccessedPage: "支払い成功ページに入る",
      },
      doAction: {
        leavePage: "ユーザーが離れるとき",
        staySeconds: "ユーザーが{{seconds}}秒間滞在したとき",
        scrollToBottom: "ユーザーがページの一番下までスクロールしたとき",
        clickAddToCart: "ユーザーが[カートに追加]ボタンをクリックしたとき",
        doNothing: "何もしない（すぐに）",
      },
      fireAction: {
        popUpCoupon: "ポップアップクーポン",
        popUpSurvey: "ポップアップアンケート",
        recommendProducts: "おすすめ商品（アップセル）",
      },
      device: {
        all: "すべての機器",
        mobile: "モバイル機器",
        pc: "PC",
      },
      noneSubscribers: "加入者なし",
      alert: {
        areYouDelete: "このポップアップウィンドウを削除してもよろしいですか？",
        areYouActive: "このポップアップウィンドウをアクティブにしてよろしいですか？",
        areYouDisable: "このポップアップウィンドウを無効にしてよろしいですか？",
      },
      buildTable: {
        discount: "割引",
        delete: "消去",
        active: "アクティベーション",
        edit: "編集",
      },
      tableList: {
        name: "名前",
        userActionFlow: "ユーザー操作プロセス",
        createTime: "作成時間",
      },
      stCouponUi: {
        trigger: "引き金",
        sessions: "二流",
        gotCoupon: "クーポンを取得する",
        closeCoupon: "クーポンを閉じる",
        placeOrder: "クーポンで注文する",
        totalAmountOfOrders: "注文合計",
      },
      stSurveyUi: {
        trigger: "引き金",
        sessions: "二流",
        submitSurvey: "調査を提出する",
        closeSurvey: "精査",
        optionAnswers: "オプションの回答",
        otherAnswers: "その他の回答",
      },
      stRecommendPtUi: {
        trigger: "引き金",
        sessions: "二流",
        addToCart: "カートに追加",
        add: "追加",
        times: "周波数",
      },
      hideAction: {
        hideCoupon: "クーポンプレビューを非表示",
        hideSurvey: "調査プレビューを非表示",
        hideRecommendPt: "おすすめの商品プレビューを非表示",
      },
      previewAction: {
        previewCoupon: "プレビュークーポン",
        previewSurvey: "プレビューアンケート",
        previewRecommendPt: "おすすめ商品のプレビュー",
      },
      itemForm: {
        editAction: "ポップアップの編集",
        hideStatistic: "統計を隠す",
        showStatistic: "統計を表示する",
        active: "アクティベーション",
        disable: "無効にする",
        userActionFlow: "ユーザー操作プロセス",
        couponDiscount: "クーポン割引",
        status: "州",
        unActive: "不活化",
        online: "オンライン",
        triggerOn: "トリガーデバイス",
        needLeftEmail: "メールボックスを離れる必要があります",
        createTime: "作成時間",
        couponPreview: "クーポンプレビュー",
        surveyPreview: "アンケートプレビュー",
        recommendPtPreview: "おすすめ商品プレビュー",
        statistics: "統計データ",
      },
      freeplan: {
        title: "現在、無料プランを使用しています",
        actionLimit: "追加できるポップアップは{{maxActions}}までです。",
        feature1: "すべてのポップアップ（クーポン、アンケートを含む）は、ユーザーのカスタマイズをサポートします",
        feature2: "ポップアップのタイミングと戦略をカスタマイズできます",
        feature3: "データ分析を取得できます",
        changePlan: "アップグレードプラン",
      },
      basicPlan: {
        changePlan: "アップグレードプラン",
        basicPlan: "基本計画",
        eachMonthFee: "$ 5.99 /月",
        sevenDaysFree: "7日間の無料トライアル",
        feature1: "無限のポップアップ",
        feature2: "すべてのポップアップ（クーポン、アンケートを含む）は、ユーザーのカスタマイズをサポートします",
        feature3: "ポップアップウィンドウがトリガーされたときに顧客に製品を推奨する（アップセル）",
        feature4: "ポップアップのタイミングと戦略をカスタマイズできます",
        feature5: "データ分析を取得できます",
        upgrade: "アップグレード",
        upgrading: "アップグレード...",
      },
      rt: {
        actions: "ポップアップ",
        addAction: "ポップアップを追加",
        productDetailPage: "商品詳細ページ",
        collectionPage: "コレクションページ",
        shoppingCartPage: "ショッピングカートページ",
        homePage: "表紙",
        paySuccessedPage: "支払い成功ページ",
        unactiveActionList: "[非アクティブ]ポップアップリスト",
      }

    },
    cartCondition: {
      cartValue: "ショッピングカートの合計金額",
      cartQuantity: "ショッピングカートのSKU数量",
      lessThan: "未満",
      greaterTo: "以上",
      totalPriceHolder: "合計金額",
      add: "追加",
    },
    collectionList: {
      title: "コレクションページリスト",
    },
    issueSuggestion: {
      successMessage: "私たちはあなたのメッセージを受け取りました、ありがとう！",
      plsTellUs: "ご不明な点がございましたら、お気軽にお問い合わせください✍️",
      issue: "問題",
      suggestion: "提案",
      atLeastTenWords: "少なくとも10語。",
      submit: "参加する",
    },
    productSearch: {
      searchPlaceHolder: "製品名で検索し、Enterキーを押します",
      addProductTitle: "製品を追加する",
      add: "追加",
      cancel: "キャンセル",
      searchPtPlaceHolder: "製品を検索する",
      searchHelpText: "製品名で検索し、Enterキーを押します",
      noResultFor: "何もデータが見つかりませんでした",
    },
    productList: {
      inStockFor: "在庫あり",
      variants: "可変パラメータ",
    },
    selectedList: {
      remove: "削除する",
    },
    settings: {
      copySuccessed: "クリップボードに正常にコピー",
      installSpec: "インストールに関する注意事項",
      inOrderTo: "クーポンによってもたらされる注文統計情報を取得するには、以下の手順に従って設定してください。",
      list1: "ストア管理パネルにログインし、左下の[設定]をクリックします。",
      list2: "左側の[チェックアウト]メニュー項目をクリックします。",
      list3: "[追加スクリプト]設定項目まで下にスクロールします。",
      list4: "次のテンプレートスクリプトを[注文ステータスページ]テキストボックスに貼り付けます。",
      list5: "[保存]ボタンをクリックして設定を保存してください。",
      templateScript: "テンプレートスクリプト",
      clicToCopy: "コピーをクリックします",
      explanationLegd: "操作の凡例の説明",
    },
    surveyEdit: {
      removeButton: "削除する",
      title: "アンケートタイトル",
      titleError: "アンケートのタイトルが必要です",
      subTitle: "アンケートサブタイトル（オプション）",
      otherOptionName: "その他のオプション名",
      otherOptionError: "他のオプション名を入力する必要があります",
      submitButton: "ボタンテキストを送信",
      submitButtonError: "送信ボタンのテキストが必要です",
      fillSurveyPlaceHolder: "ここにアンケートオプションを記入してください",
      fillSurveyHelpText: "追加できるオプションの最大数は次のとおりです。",
      addOption: "オプションを追加",
      surveyCustomAttr: "<<ユーザーアンケートのカスタマイズされた属性>>",
      bkGroundImgOpt: "背景画像（オプション）：",
      fillImgUrl: "画像のURLを入力します",
      bkGroundColor: "背景色：",
      textColor: "テキストの色：",
      buttonBkGroundColor: "ボタンの背景色：",
      buttonBorderColor: "ボタンの境界線の色：",
      hideCloseButton: "ユーザー調査を非表示にする閉じるボタン",
    },
    upsell: {
      productList: {
        inStockFor: "在庫あり",
        variants: "可変パラメータ",
      },
      recommendPts: {
        deleteAlert: "この商品を削除してもよろしいですか？",
      },
      edit: {
        updateRecommendProducts: "推奨製品情報を更新する",
        upsellRecommentPtsToUser: "アップセル-ユーザーに製品を推奨する",
        searchByProduct: "製品名で検索し、Enterキーを押します",
        modalTitle: "製品を追加する",
        primaryAddBtn: "追加",
        secondCancelBtn: "キャンセル",
        modalSearchPlaceHolder: "製品を検索する",
        modalSearchHelpText: "製品名で検索し、Enterキーを押します",
        noResultFound: "何もデータが見つかりませんでした",
        recommendProductsAttr: "<<推奨される製品のカスタム属性>>",
        upsellTitle: "アップセルタイトル：",
        fillTitlePlaceHolder: "タイトルを記入してください",
        addToCartText: "カートテキストに追加：",
        fillAddToCartPlaceHolder: "ショッピングカートに追加されたテキストを入力します",
        addToCartButtonTxtColor: "カートボタンのテキストの色に追加：",
        addToCartButtonBkColor: "カートボタンの背景色に追加：",
        displayWay: "表示方法",
        displayPopUpOpt: "現れる",
        displayBelowATCBtnOpt: "[カートに追加]ボタンの下に表示されます",
        displayInlineThankYouPageOpt: "支払い成功ページに埋め込まれています（ありがとうページ）",
      }
    },
    leaveComments: {
      content: "私たちのアプリにコメントを残してもらえますか？ どうもありがとうございます",
      clickHere: "ここをクリック",
    },
    httpRequest: {
      authFailed: "セッションの有効期限が切れると、プログラムは自動的にページを更新します。",
    }
  }

}
