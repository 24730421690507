
import React, { useState, useCallback, useEffect, Fragment } from 'react';
import './App.css';

import {AppProvider, Card, Tabs, FooterHelp, Link, Button} from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';
import zhTranslations from '@shopify/polaris/locales/zh-CN.json';
// import jaTranslations from '@shopify/polaris/locales/ja.json';
import {Provider} from '@shopify/app-bridge-react';
import createApp from "@shopify/app-bridge";
import { withTranslation } from "react-i18next";
import Action from './components/action';
import Settings from './components/settings';
import Helper from './components/helper';
import { AppContext } from './context-manager';
import WebFont from 'webfontloader';
import CollectionReorder from './components/collectionReorder';
import i18n from './i18n';
import { getCurrentUser, updateLanguageShow } from './api/shop';

const fontFamilyOptions = [
  'Nanum Myeongjo', 
  'Fredoka One',
  'Slackey',
  'Limelight',
  'Paytone One',
  'Sora',
  'Hahmlet',
  'JetBrains Mono',
  'Andada Pro',
  'Epilogue',
  'Fjalla One',
  'Encode Sans',
  'Manrope',
  'Lora',
  'Archivo',
  'Roboto',
  'Cormorant',
  'Spectral',
  'Raleway',
  'Work Sans',
  'Lato',
  'Anton',
  'Old Standard TT',
  'Oswald',
  'Montserrat',
  'Poppins',
  'Nunito',
  'Source Sans Pro',
  'Oxygen',
  'Yeseva One',
  'Gideon Roman',
  ]

const App = ({ t }) => {
  const [selected, setSelected] = useState(0)
  const [shopInfo, setShopInfo] = useState()
  const [invalidShop, setInvalidShop] = useState(false)
  const [currentSelectedLng, setCurrentSelectedLng] = useState('en')
  const [uiTrans, setUiTrans] = useState(enTranslations)

  useEffect(() => {
    async function fetchData() {
      const data = await getCurrentUser()
      if(data) {
        if(data.code && data.code === 5000) {
          setInvalidShop(true)
        } else {
          // console.log(data)
          i18n.changeLanguage(data.showLanguage)
          setCurrentSelectedLng(data.showLanguage)
          switch(data.showLanguage) {
            case 'en':
              setUiTrans(enTranslations)
              break
            case 'zh':
              setUiTrans(zhTranslations)
              break
            // case 'ja':
            //   setUiTrans(jaTranslations)
              break
            default:
              setUiTrans(enTranslations)
              break
          }
          setShopInfo(data)
        }
      }
    }
    fetchData();

  }, [])

  useEffect(() => {
    WebFont.load({
      google: {
        families: fontFamilyOptions
      }
    });
  }, [])
  
  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelected(selectedTabIndex),
    [],
  );

  const tabs = [
    {
      id: 'tb-action',
      content: t("app.action"),
    },
    // {
    //   id: 'tb-collection-reorder',
    //   content: t("app.collectionReorder"),
    // },
    {
      id: 'tb-settings',
      content: t("app.settings"),
    },
    {
      id: 'tb-help',
      content: t("app.help")
    }

  ];

  const renderSwitch = (sel) => {
    let component = null 
    switch(sel) {
      case 0:
        component = <Action />
        break   
      // case 1:
      //   component = <CollectionReorder />
      //   break
      case 1:
        component = <Settings />
        break
      case 2:
        component = <Helper />
        break
      default:
        component = null
    }
    return component
  }

  const jumpTo = (url) => {
    window.top.location.href = url
  }

  const handleLanguageChange = async (code) => {

    // i18n.changeLanguage(code)
    // setCurrentSelectedLng(code)
    const rt = await updateLanguageShow(code)
    if(rt) {
      window.location.reload()
    }

  }

  const handleClickToComments = () => {
    window.top.location.href = 'https://apps.shopify.com/when-the-user-leaves'
  }
  // const handleClickToShortenUrl = () => {
  //   window.top.location.href = 'https://aiaurl.com'
  // }

  const reinstallStyle = {
    "padding": "30px",
    "fontSize": "20px"
  }
  const apiKey =  process.env.SHOPIFY_API_KEY
  const urlParams = new URLSearchParams(window.location.search)
  const host = urlParams.get("host")

  window._embeded_app = createApp({
    apiKey: apiKey,
    host: host
  })

  const config = {
    apiKey: apiKey,
    host: host,
    forceRedirect: true,
  }
  return (
    <Fragment>
    {invalidShop === false && <AppContext.Provider value={{shopInfo: shopInfo, fontFamilyOptions: fontFamilyOptions}}>
    <AppProvider i18n={uiTrans}>
      <Provider config={config}>
      <Card>
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
        </Tabs>
      </Card>
      {renderSwitch(selected)}
      <div className="comments">{t("leaveComments.content")} <Button plain onClick={handleClickToComments}>{t("leaveComments.clickHere")}</Button></div>
      <FooterHelp>
      {t("app.contactUs")} {' '}
        <Link onClick={() => jumpTo('mailto:support@goldengate.work')}>
        support@goldengate.work
        </Link>
      </FooterHelp>
      
      <div className="languageSelector">
        <span  onClick={() => handleLanguageChange('en')} className={currentSelectedLng === 'en' ? "language active" : "language"}>English</span>
        <span  onClick={() => handleLanguageChange('zh')} className={currentSelectedLng === 'zh' ? "language active" : "language"}>中文</span>
        <span  onClick={() => jumpTo('mailto:support@goldengate.work')} className={"tools"}>{t("app.submmitIssues")}</span>
        <span  onClick={handleClickToComments} className={"tools"}>{t("app.leaveComments")}</span>
      </div>
      </Provider>
    </AppProvider>
    </AppContext.Provider>}
    {invalidShop === true && <h2 style={reinstallStyle}>Please re-install this app.</h2>}
    </Fragment>
  );
}

export default withTranslation()(App);

