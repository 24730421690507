import React, { Fragment } from 'react';
import { Page, Card} from '@shopify/polaris';
import styles from './index.module.scss';
import Navigate from '../navigate';

const Index = ({language = 'en'}) => {

  const enContents = <Card title="PopUp - All in one (PUIO)">
  <div className={styles.box}>
    <p className={styles.attention}>* Unless otherwise specified, PUIO will be used instead app name *</p>

    <p>PUIO Privacy Policy</p>

    <p>PUIO "the App” provides retain customers "the Service" to merchants who use Shopify to power their stores. This Privacy Policy describes how personal information is collected, used, and shared when you install or use the App in connection with your Shopify-supported store.</p>

    <p>Personal Information the App Collects</p>

    <p>When you install the App, we are automatically able to access certain types of information from your Shopify account: product data, store name</p>

    <p>We collect personal information directly from the relevant individual, through your Shopify account, or using the following technologies: “Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit http://www.allaboutcookies.org. “Log files” track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps. “Web beacons,” “tags,” and “pixels” are electronic files used to record information about how you browse the Site.</p>

    <p>How Do We Use Your Personal Information?</p>

    <p>We use the personal information we collect from you and your customers in order to provide the Service and to operate the App. Additionally, we use this personal information to: Communicate with you; Optimize or improve the App; and Provide you with information or advertising relating to our products or services.</p>

    <p>Sharing Your Personal Information</p>

    <p>We may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.</p>

    <p>PUIO does not knowingly collect Personal Data from children under the age of 13. If you are under the age of 13, please do not submit any Personal Data through the Services. We encourage parents and legal guardians to monitor their children’s Internet usage and to help enforce our Privacy Policy by instructing their children never to provide Personal Data on the Services without their permission. If you have reason to believe that a child under the age of 13 has provided Personal Data to PUIO through the Services, please contact us, and we will endeavor to delete that information from our databases.</p>

    <p>Your Rights If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below.</p>

    <p>Additionally, if you are a European resident we note that we are processing your information in order to fulfill contracts we might have with you (for example if you make an order through the Site), or otherwise to pursue our legitimate business interests listed above. Additionally, please note that your information will be transferred outside of Europe, including to Canada and the United States.</p>

    <p>Data Retention</p>

    <p>When you place an order through the Site, we will maintain your Order Information for our records unless and until you ask us to delete this information.</p>

    <p>Changes We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.</p>

    <p>Contact Us For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at support@goldengate.work</p>
    <p></p>
  </div>
  </Card>

  const jaContents = <Card title="PopUp-クーポン、アップセル、調査 (PUIO)">
  <div className={styles.box}>
    <p className={styles.attention}>* 特に指定のない限り、アプリ名の代わりにPUIOが使用されます *</p>

    <p>PUIOプライバシーポリシー</p>

    <p>PUIOの「アプリ」は、Shopifyを使用して店舗に電力を供給する販売者に顧客に「サービス」を提供します。このプライバシーポリシーでは、Shopifyがサポートするアプリに関連してアプリをインストールまたは使用するときに、個人情報を収集、使用、共有する方法について説明します。 お店。</p>

    <p>アプリが収集する個人情報</p>

    <p>アプリをインストールすると、Shopifyアカウントから特定の種類の情報（製品データ、ストア名）に自動的にアクセスできるようになります</p>

    <p>当社は、Shopifyアカウントを介して、または次のテクノロジーを使用して、関連する個人から直接個人情報を収集します。「Cookie」は、デバイスまたはコンピューターに配置されるデータファイルであり、多くの場合、匿名の一意の識別子が含まれます。 Cookieの詳細、およびCookieを無効にする方法については、http：//www.allaboutcookies.orgにアクセスしてください。 「ログファイル」は、サイトで発生するアクションを追跡し、IPアドレス、ブラウザの種類、インターネットサービスプロバイダー、参照/終了ページ、日付/タイムスタンプなどのデータを収集します。 「ウェブビーコン」、「タグ」、「ピクセル」は、サイトの閲覧方法に関する情報を記録するために使用される電子ファイルです。</p>

    <p>私たちはあなたの個人情報をどのように使用しますか？</p>

    <p>当社は、サービスを提供し、アプリを操作するために、お客様およびお客様の顧客から収集した個人情報を使用します。 さらに、この個人情報は次の目的で使用されます。 アプリを最適化または改善します。 および当社の製品またはサービスに関連する情報または広告をお客様に提供します。</p>

    <p>個人情報の共有</p>

    <p>また、適用される法律や規制を遵守するため、召喚状に対応するため、令状を検索するため、またはその他の合法的な情報要求に応じるため、またはその他の方法で当社の権利を保護するために、お客様の個人情報を共有する場合があります。</p>

    <p>PUIOは、13歳未満の子供から故意に個人データを収集することはありません。13歳未満の場合は、本サービスを通じて個人データを送信しないでください。 保護者および法定後見人は、お子様のインターネットの使用状況を監視し、お子様に許可なく本サービスで個人データを提供しないように指示することにより、プライバシーポリシーの実施を支援することをお勧めします。 13歳未満の子供が本サービスを通じてPUIOに個人データを提供したと思われる場合は、当社までご連絡ください。当社のデータベースからその情報を削除するよう努めます。</p>

    <p>あなたの権利あなたがヨーロッパの居住者である場合、あなたは私たちがあなたについて保持している個人情報にアクセスし、あなたの個人情報を修正、更新、または削除するよう求める権利があります。 この権利の行使をご希望の場合は、以下の連絡先までご連絡ください。</p>

    <p>さらに、お客様がヨーロッパ在住の場合、お客様との契約を履行するため（たとえば、サイトを通じて注文した場合）、または上記の正当なビジネス上の利益を追求するために、お客様の情報を処理していることに注意してください。 さらに、あなたの情報はカナダや米国を含むヨーロッパ以外に転送されることに注意してください。</p>

    <p>データ保持</p>

    <p>あなたがサイトを通して注文するとき、あなたがこの情報を削除するように私たちに要求しない限り、私たちは私たちの記録のためにあなたの注文情報を保持します。</p>

    <p>変更当社は、たとえば、当社の慣行の変更を反映するため、またはその他の運用上、法律上、または規制上の理由により、このプライバシーポリシーを随時更新する場合があります。</p>

    <p>お問い合わせ当社のプライバシー慣行の詳細について、ご質問がある場合、または苦情を申し立てたい場合は、support@goldengate.work まで電子メールでご連絡ください。</p>
    <p></p>
  </div>
  </Card>


  const privacyBuilder = () => {
    switch(language) {
      case "en":
        return enContents
      case "jp":
        return jaContents
      default:
        return enContents
    }
  }
  return (
    <Fragment>
    <Navigate />
    <Page>
      {
        privacyBuilder()
      }
    </Page>
    </Fragment>
  )
}

export default Index
