import React from 'react';
// import { Page, Button } from '@shopify/polaris';
import Navigate from '../navigate';
import styles from './index.module.scss';

const Home = () => {

  return <div className={styles.home}>
    <Navigate />
  </div>

}

export default Home