import React, { useState, useEffect } from "react"
import {  ChoiceList } from '@shopify/polaris';
// import { SearchMinor } from '@shopify/polaris-icons';
import styles from "./index.module.scss"
import { withTranslation } from 'react-i18next';

import { getAllCollections } from '../../api/shop'


const CollectionList = ({initSelectedItems = [], onItemChanged, t}) => {
  const [optionCollections, setOptionCollections] = useState([])
  // console.log(initSelectedItems)
  const [selected, setSelected] = useState((initSelectedItems && initSelectedItems.map(item => item.sourceId)) || [])
  const loadOptions = () => {
    getAllCollections().then(data => {
      setOptionCollections(data.items || [])
    })
  }


  useEffect(loadOptions, [])


  const handleChange = async (selectedIds) => {
    if(onItemChanged) {
      let items = []
      if(selectedIds.length > 0) {
        for(let i = 0; i < selectedIds.length; i++) {
          for(let j = 0; i < optionCollections.length; j++) {
            if(selectedIds[i] === optionCollections[j].collectionId) {
              items.push({'sourceId': selectedIds[i], 'handle': optionCollections[j].handle, 'title': optionCollections[j].title})
              break
            }
          }
        }
      }
      onItemChanged(items)
    }
    await setSelected(selectedIds)

  }

  const collectionOptionList = optionCollections.map((item, k) => {
    return {'label': item.title, 'value': item.collectionId}
  })

  return (<div className={styles.collectionBox}>
   <ChoiceList
      allowMultiple
      title={t("collectionList.title")}
      choices={collectionOptionList}
      selected={selected}
      onChange={handleChange}
    />
  </div>)
}
export default withTranslation()(CollectionList)
