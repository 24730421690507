import React, {useState} from "react"
import styles from './surveyPreview.module.scss'
import RadioButton from "../radiobutton"

const SurveyPreview = ({survey = {}}) => {
  const [currentCheckId, setCurrentCheckId] = useState(0)

  let boxStyle = {
    backgroundColor: survey.bgColor,
    color: survey.textColor
  }
  // if(survey.bgImage && survey.bgImage !== "") {
  //   boxStyle = {...boxStyle, 
  //     backgroundImage: `url("${survey.bgImage}")`,
  //     backgroundPosition: '50% 0',
  //     backgroundRepeat: 'no-repeat',
  //     backgroundSize: 'cover'
  //   }
  // }

  let cardImageStyle = {}
  if(survey.bgImage && survey.bgImage !== "") {
    cardImageStyle = {...cardImageStyle, 
      backgroundImage: `url("${survey.bgImage}")`,
      backgroundPosition: '50% 50%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      minHeight: "280px",
    }
  }

  const buttonStyle = {
    color: survey.btnTextColor ? survey.btnTextColor : `#fff`,
    backgroundColor: `${survey.btnBgColor}`,

  }

  const optionList = survey.options.map((item,index) => {
    return <li key={item.id}>
      <RadioButton checked={currentCheckId === index + 1} onChange={() => setCurrentCheckId(index + 1)}>{index + 1}.  {item.title}</RadioButton>
    </li>
  })

  const textareaStyle = {
    // backgroundColor: survey.bgColor
  }
  const closeStyle = {
    color: `${survey.textColor}`,
  }

  const otherOption = survey.options && survey.options.length > 0 && <li>
    <RadioButton checked={currentCheckId === survey.options.length + 1} onChange={() => setCurrentCheckId(survey.options.length + 1)}>{survey.options.length + 1}. {survey.otherText}</RadioButton>
    <div className={styles.textareaBox}><textarea rows="3" style={textareaStyle} disabled={currentCheckId !== survey.options.length + 1}></textarea></div>
  </li>

  return (
    <div className={styles.surveybox} style={boxStyle}>
      <div className={styles.cardItem}>
      <div className={styles.title}>{survey.title}</div>
      {survey.subTitle && survey.subTitle !== "" && <div className={styles.subTitle}>{survey.subTitle}</div>}
      <div className={styles.options}>
        <ul>
          {optionList}
          {otherOption}
        </ul>
      </div>
      <div className={styles.submit}><button style={buttonStyle}>{survey.submitText}</button></div>
      </div>
      {(survey.bgImage && survey.bgImage !== "") && <div className={styles.cardItem} style={cardImageStyle}>
        </div>}
      { !survey.hideCloseButton && <div className={styles.close} style={closeStyle}>&#x2715;</div> }
    </div>
  )
}

export default SurveyPreview