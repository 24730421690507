import React from "react"
import {Card, Page, PageActions,  FormLayout, TextField, Select, Stack, RadioButton, Checkbox} from '@shopify/polaris';
import { CommonPageState } from "../../utils/common_state"
import CouponEdit from "./couponEdit";
import SurveyEdit from "../survey/surveyEdit";
import SubscriberEdit from "../subscriber/edit";
import styles from './edit.module.scss';
import { addAction, updateAction } from '../../api/action'
import ProductSearch from '../productSearch/index'
import CollectionList from "../collectionList";
import CartFireCondition from "../cartCondition";
import UpSellEdit from "../upsell/upsellEdit";
import { AppContext } from "../../context-manager";
import { withTranslation } from 'react-i18next';


class Index extends React.Component {
  static contextType = AppContext  //ref: https://reactjs.org/docs/context.html

  constructor(props) {
    super(props)
    const { t } = props
    this.defaultEmailReminderText = t("edit.emailReminderText") 
    this.leavePageOptionItem = {label: t("edit.leavePageOptionText"), value: 'LEAVE'}
    this.stayOnPageOptionItem = {label: t("edit.stayOnPageOptionText"), value: 'STAY_IN_SECONDS'}
    this.scrollBottomOptionItem = {label: t("edit.scrollBottomOptionText"), value: 'SCROLL_TO_BOTTOM'}
    this.addToCartOptionItem = {label: t("edit.addToCartOptionText"), value: 'CLICK_ADD_TO_CART'}
    this.addDoNothingOptionItem = {label: t("edit.addDoNothingText"), value: 'DO_NOTHING'}


    this.defaultFireOptions = [
      this.leavePageOptionItem, this.stayOnPageOptionItem, this.scrollBottomOptionItem, this.addDoNothingOptionItem
    ]
    this.productDetailFireOptions = [
      this.leavePageOptionItem, this.stayOnPageOptionItem, this.scrollBottomOptionItem, this.addToCartOptionItem, this.addDoNothingOptionItem
    ]
    this.homePageFireOptions = [
      this.leavePageOptionItem, this.stayOnPageOptionItem, this.scrollBottomOptionItem, this.addDoNothingOptionItem
    ]
    this.paySuccessedPageFireOptions = [
      this.stayOnPageOptionItem, this.scrollBottomOptionItem, this.addDoNothingOptionItem
    ]

    this.state = {
      formData: {
        name: "",
        comeIn: 5,
        doAction: 0,
        actionApplyTo: 0,
        coupon: {
          type: 0,
          discountValue: "20",
          expirationHours: "48",
          codePrefix: "WL",
          title: t("edit.coupon.title"),
          subTitle: t("edit.coupon.subTitle"),
          gotButtonText: t("edit.coupon.gotButtonText"),
          applyRemindText: t("edit.coupon.applyRemindText"),
          pageRemindText: t("edit.coupon.pageRemindText"),
          bgColor: "#FECE2F",
          txtColor: "#000000",
          btnBgColor: "#CA3333",
          btnTextColor: "#FFF",
          currencySymbol: "$",
          bgImage: '',
          needLeftEmail: false,
          needEmailRemindText: this.defaultEmailReminderText,
        },
        survey: {
          title: t("edit.survey.title"),
          subTitle: t("edit.survey.subTitle"),
          otherText: t("edit.survey.otherText"),
          submitText: t("edit.survey.submitText"),
          bgColor: "#fff",
          textColor: "#000",
          btnBgColor: "#325BC0",
          btnTextColor: "#FFF",
          bgImage: '',
          canModifyOption: true,
          couponRemindText: 'Congratulations! Got a coupon code',
          options: [
            {id: 1, title: t("edit.survey.option1")},
            {id: 2, title: t("edit.survey.option2")},
          ]
        },
        subscriber: {
          title: t("edit.subscriber.title"),
          subTitle: t("edit.subscriber.subTitle"),
          unSubDesc: t("edit.subscriber.unSubDesc"),
          bkColor: "#FFFFFF",
          btnBkColor: "#C82F50",
          btnTextColor: "#FFFFFF",
          textColor: "#000000",
          placeHolderText: "Your email address",
          btnText: "SUBSCRIBE",
          bgImage: '',
        },
        fireMode: "LEAVE",
        staySeconds: "10",
        comeInItems: [],
        cartConditions: [],
        productDetailPages: [],
        collectionPages: [],
        showInDevice: '0',
        upsell: {
          title: t("edit.upsell.title"),
          addToCartText: t("edit.upsell.addToCartText"),
          addToCartTextColor: "white",
          addToCartBkColor: "#35373a",
          displayMethod: '0'
        },
        recommendProducts: [],
        enableURLRestrict: false,
        urlPattern: '',
      },
      validation: {
        actionNameErr: false,
        staySecondsErr: false,
      },
      disableSaveBtn: true,
      fireModeOptions: this.defaultFireOptions
    }

    if(props.editData && props.editData.id > 0) {

      let editData = {...this.props.editData}
      editData.comeIn = editData.comeIn.toString()
      editData.doAction = editData.doAction.toString()
      if(editData.coupon) {
        editData.coupon.discountValue = editData.coupon.discountValue.toString()
        editData.coupon.expirationHours = editData.coupon.expirationHours.toString()
        editData.coupon.type = editData.coupon.type.toString()
        editData.coupon.currencySymbol = editData.coupon.currencySymbol || '$'
        if(editData.coupon.needEmailRemindText === "") {
          editData.coupon.needEmailRemindText = this.defaultEmailReminderText
        }
      }

      editData.staySeconds = editData.staySeconds.toString()
      editData.showInDevice = editData.showInDevice.toString()


      this.state.formData = editData
      switch(parseInt(this.state.formData.comeIn)) {
        case 2:
          this.state.formData.cartConditions = [...(editData.cartConditions || [])]
          break
        case 3:
          this.state.formData.productDetailPages = [...editData.comeInItems]
          break
        case 4:
          this.state.formData.collectionPages = [...editData.comeInItems]
          break
        default:
          break
          // console.log(this.state.formData.comeIn)
      }
      // console.log(this.buildDoActionOptionsByComeIn(parseInt(editData.comeIn)))
      this.state.fireModeOptions = this.buildDoActionOptionsByComeIn(parseInt(editData.comeIn))
      // this.setState({fireModeOptions: })
    }

    
  }
  
  async componentDidMount() {

    // this.handleLFromSelectChange(5)
  }

  handleNameChange = async (v) => {
    await this.setState({formData: {...this.state.formData, name: v}})
    this.resetSubmitBtnStatus()
  }

  handleLFromSelectChange = async (v) => {
    await this.setState({formData: {...this.state.formData, comeIn: v}})
    const comein = parseInt(v)

    const options = this.buildDoActionOptionsByComeIn(comein)
    await this.setState({fireModeOptions: options})

    this.handleFireModeSelectChange(options[0].value)

    this.resetSubmitBtnStatus()
  }

  buildDoActionOptionsByComeIn = (comein) => {
    let actions = [...this.defaultFireOptions]
    if(comein === 0 || comein === 3) {
      actions = [...this.productDetailFireOptions]
    } 
    if(comein === 5) {
      actions = [...this.homePageFireOptions]
    }
    if (comein === 6) {
      actions = [...this.paySuccessedPageFireOptions]
    }
    return actions
  }

  handleLASelectChange = async (v) => {
    await this.setState({formData: {...this.state.formData, doAction: v}})
    this.resetSubmitBtnStatus()
  }

  handleFireModeSelectChange = async (v) => {
    await this.setState({formData: {...this.state.formData, fireMode: v}})
    this.onStaySecondsValMoved()
    this.resetSubmitBtnStatus()

  }

  handleApplyToSelectChange = (v) => {
    this.setState({formData: {...this.state.formData, actionApplyTo: v}})
  }

  handleTrafficRateChange = (v) => {
    this.setState({formData: {...this.state.formData, viewRatio: v}})
  }

  handleCouponInfoChange = (c) => {
    this.setState({formData: {...this.state.formData, coupon: c}})
    this.resetSubmitBtnStatus()
  }

  handleCouponOnError = () => {
    this.resetSubmitBtnStatus()
  }

  handleSurveyInfoChange = (s) => {
    this.setState({formData: {...this.state.formData, survey: s}})
    this.resetSubmitBtnStatus()
  }

  handleSurveyOnError = () => {
    this.resetSubmitBtnStatus()
  }

  handleSubscriberInfoChange = (s) => {
    this.setState({formData: {...this.state.formData, subscriber: s}})
    this.resetSubmitBtnStatus()
  }

  handleSubscribeOnError = () => {
    this.resetSubmitBtnStatus()
  }

  handleRecommendProductsChange = (pts) => {
    this.setState({formData: {...this.state.formData, recommendProducts: pts}})
    this.resetSubmitBtnStatus()
  }

  handleUpSellChange = (u) => {
    this.setState({formData: {...this.state.formData, upsell: u}})
    this.resetSubmitBtnStatus()
    // console.log(u)
  }

  handleStaySecondsValChange = async (v) => {
    this.setState({formData: {...this.state.formData, staySeconds: v}})
    this.resetSubmitBtnStatus()
  }

  onActionNameMoved = async () => {
    if(this.state.formData.name === "") {
      await this.setState({validation: {...this.state.validation,  actionNameErr: true}})
    } else {
      await this.setState({validation: {...this.state.validation,  actionNameErr: false}})
    }
    this.resetSubmitBtnStatus()
  }

  handleEnableURLRestrictChange = async () => {
    await this.setState({formData: {...this.state.formData, enableURLRestrict: !this.state.formData.enableURLRestrict}})
    this.resetSubmitBtnStatus()
  }

  onUrlPatternMoved = async () => {
    if(this.state.formData.enableURLRestrict && this.state.formData.urlPattern.trim() === "") {
      await this.setState({validation: {...this.state.validation,  urlPatternErr: true}})
    } else {
      await this.setState({validation: {...this.state.validation,  urlPatternErr: false}})
    }
    this.resetSubmitBtnStatus()
  }

  handleUrlPatternChange = async (v) => {
    await this.setState({formData: {...this.state.formData, urlPattern: v}})
    this.resetSubmitBtnStatus()
  }

  onStaySecondsValMoved = async () => {
    if(this.state.formData.fireMode === "STAY_IN_SECONDS" && (this.state.formData.staySeconds === "" || parseInt(this.state.formData.staySeconds) === 0)) {
      await this.setState({validation: {...this.state.validation,  staySecondsErr: true}})
    } else {
      await this.setState({validation: {...this.state.validation,  staySecondsErr: false}})
    }
    this.resetSubmitBtnStatus()
  }

  resetSubmitBtnStatus = () => {
    this.setState({disableSaveBtn: (
      this.state.formData.name === ""
      || this.specialActionRequired()
      || (this.state.formData.fireMode === "STAY_IN_SECONDS" && (this.state.formData.staySeconds === "" || parseInt(this.state.formData.staySeconds) === 0))
    )})
 
  }

  handleProductSearchResultChanged = async (pts) => {
    const items = pts.map((item) => ({'sourceId': item.productPlatformId, 'title': item.title, 'imageUrl': item.image, 'handle': item.handle}))
    await this.setState({formData: {...this.state.formData, productDetailPages: items}})
    this.resetSubmitBtnStatus()
  }


  handleCollectionChanged = async (items) => {
    await this.setState({formData: {...this.state.formData, collectionPages: items}})
    this.resetSubmitBtnStatus()
  }
  
  handleApplyDeviceChange = (_chk, val) => {
    this.setState({formData: {...this.state.formData, showInDevice: val}})
    this.resetSubmitBtnStatus()
  }

  specialActionRequired = () => {
    switch(parseInt(this.state.formData.doAction)) {
      case 0:
        return this.state.formData.coupon.title === "" 
        || this.state.formData.coupon.gotButtonText === ""
        || this.state.formData.coupon.discountValue === ""
      case 1:
        return this.state.formData.survey.title === "" 
        || this.state.formData.survey.otherText === ""
        || this.state.formData.survey.submitText === ""
      case 2:
        return this.state.formData.recommendProducts.length === 0
      case 3:
        return this.state.formData.subscriber.title === "" 
        || this.state.formData.subscriber.placeHolderText === ""
        || this.state.formData.subscriber.btnText === ""
        || this.state.formData.subscriber.unSubDesc === ""
      default:
        return false 
    }
  }
  fireConditions = () => {
    const { t } = this.props
    return <div>{t("edit.cart.fireAtLeastOneItem")} <span role="img" aria-label=""> 🛒</span></div>
  }

  doActionComponents = () => {
    switch(parseInt(this.state.formData.doAction)) {
      case 0:
        return <CouponEdit couponInfo={this.state.formData.coupon} onChange={this.handleCouponInfoChange} onError={this.handleCouponOnError} />
      case 1:
        return <SurveyEdit surveyInfo={this.state.formData.survey} onChange={this.handleSurveyInfoChange} onError={this.handleSurveyOnError} />
      case 2:
        return <UpSellEdit upsellInfo={this.state.formData.upsell} upsellProducts={this.state.formData.recommendProducts} onChange={this.handleRecommendProductsChange} onAttrChange={this.handleUpSellChange} comeIn={this.state.formData.comeIn} />
      case 3:
        return <SubscriberEdit subscriberInfo={this.state.formData.subscriber} onChange={this.handleSubscriberInfoChange} onError={this.handleSubscribeOnError} />      
      default:
        return ""
    }
  }

  handleCartConditionChange = (items) => {

    const tempItems = [...items] 
    tempItems.forEach((item,index) => {
      item.operator = parseInt(item.operator)
      item.value = parseFloat(item.value)
      item.logic = parseInt(item.logic)
      item.conditionOrder = index + 1
    })
    // console.log(tempItems)
    this.setState({formData: {...this.state.formData, cartConditions: tempItems}})
    this.resetSubmitBtnStatus()
  }

  handleSubmit = () => {
    const { t } = this.props
    let sendData = {...this.state.formData}
    sendData.doAction = parseInt(sendData.doAction)
    sendData.urlPattern = sendData.urlPattern.trim()

    switch(sendData.doAction) {
      case 0:
        sendData.coupon.discountValue = parseFloat(sendData.coupon.discountValue)
        sendData.coupon.expirationHours = parseInt(sendData.coupon.expirationHours)
        sendData.coupon.type = parseInt(sendData.coupon.type)
        if(sendData.coupon.needEmailRemindText === '') {
          sendData.coupon.needEmailRemindText = this.defaultEmailReminderText
        }
        sendData.survey = {}
        sendData.recommendProducts = []
        sendData.upsell = {}
        sendData.subscriber = {}
        break
      case 1:
        sendData.coupon = {}
        sendData.recommendProducts = []
        sendData.upsell = {}
        sendData.subscriber = {}
        break
      case 2:
        sendData.survey = {}
        sendData.coupon = {}
        sendData.subscriber = {}
        break
      case 3:
        sendData.survey = {}
        sendData.coupon = {}
        sendData.recommendProducts = []
        break
      default:
        break

    }

    sendData.comeIn = parseInt(sendData.comeIn)
    sendData.staySeconds = parseInt(sendData.staySeconds)
    sendData.showInDevice = parseInt(sendData.showInDevice)
   

    switch(parseInt(sendData.comeIn)) {
      case 3:
        if(!sendData.productDetailPages || sendData.productDetailPages.length === 0) {
          alert(t("edit.alert.pleaseSelectProducts"))
          return 
        }
        sendData.comeInItems = [...sendData.productDetailPages]
        break
      case 4:
        if(!sendData.collectionPages || sendData.collectionPages.length === 0) {
          alert(t("edit.alert.pleaseSelectCollections"))
          return 
        }
        sendData.comeInItems = [...sendData.collectionPages]
        break
      default:
        break
        // console.log(sendData.comeIn)
    }

    if(sendData.upsell && sendData.upsell.displayMethod) {
      sendData.upsell.displayMethod = parseInt(sendData.upsell.displayMethod)
    }
    if(this.props.editData && this.props.editData.id > 0) {
      updateAction(this.props.editData.id, sendData).then(dt => {
        this.props.sendChgState(CommonPageState.ListWithItems, sendData.comeIn)
      })
    } else {
      addAction(sendData).then(dt => {
        this.props.sendChgState(CommonPageState.ListWithItems, sendData.comeIn)
      })
    }


  }

  render() {
    const { t } = this.props
    const { shopInfo } = this.context
    // console.log(shopInfo)
    const comeInOptions = [
      {label: t("edit.comeIn.homePage"), value: '5'},
      {label: t("edit.comeIn.anyProductDetailPage"), value: '0'},
      {label: t("edit.comeIn.anyCollectionPage"), value: '1'},
      {label: t("edit.comeIn.shoppingCart"), value: '2'},
      {label: t("edit.comeIn.specialProductDetailPage"), value: '3'},
      {label: t("edit.comeIn.specialCollectionPage"), value: '4'},
      
      {label: t("edit.comeIn.paySuccessedPage"), value: '6'},
    ]

    const doActionOptions = [
      {label: t("edit.doAction.popUpCoupon"), value: '0'},
      {label: t("edit.doAction.popUpSurvey"), value: '1'},
      {label: t("edit.doAction.popUpSubscribe"), value: '3'},
    ]
    if(parseInt(shopInfo.plan) > 0) {
      doActionOptions.push({label: t("edit.doAction.recommendProducts"), value: '2'})
    }

    const title = (this.props.editData && this.props.editData.id > 0) ? t("edit.buttonText.editAction") : t("edit.buttonText.addAction")
    const btnName = (this.props.editData && this.props.editData.id > 0) ? t("edit.buttonText.update") : t("edit.buttonText.save")

    return (
      <Page
        breadcrumbs={[{content: t("edit.page.actions"), onAction: () => this.props.sendChgState(CommonPageState.ListWithItems, this.state.formData.comeIn)}]}
        title={title}
        primaryAction={{content: btnName, disabled: this.state.disableSaveBtn, onAction: this.handleSubmit}}
      >
        <Card sectioned>
            <FormLayout>
              <TextField value={this.state.formData.name} 
              onChange={this.handleNameChange} 
              label={t("edit.actionAttr.name")}
              type="text" 
              placeholder={t("edit.actionAttr.namePlaceHolder")}
              onBlur={this.onActionNameMoved}
              error={this.state.validation.actionNameErr ? t("edit.actionAttr.nameError") : ""}
              />
              <FormLayout.Group>
              <Select label={t("edit.actionAttr.userComesIn")} options={comeInOptions} onChange={this.handleLFromSelectChange} value={this.state.formData.comeIn} />
              <Select label={t("edit.actionAttr.userDoseAction")} options={this.state.fireModeOptions} onChange={this.handleFireModeSelectChange} value={this.state.formData.fireMode} />
              {this.state.formData.fireMode === "STAY_IN_SECONDS" && <TextField value={this.state.formData.staySeconds} 
                  onChange={this.handleStaySecondsValChange} 
                  label={t("edit.actionAttr.staySeconds")}
                  type="number" 
                  placeholder={t("edit.actionAttr.stayPlaceHolder")}
                  min="1"
                  max="99" 
                  onBlur={this.onStaySecondsValMoved}
                  error={this.state.validation.staySecondsErr ? t("edit.actionAttr.stayError") : ""}
                  helpText={
                    <span></span>
                  }
                  />
              }
              <Select label={t("edit.actionAttr.thenTriggerAction")} options={doActionOptions} onChange={this.handleLASelectChange} value={this.state.formData.doAction} disabled={this.state.formData.id > 0} />
              {/* {
                parseInt(this.state.formData.doAction) === 0 && <Select label="Action apply to" options={applyToOptions} onChange={this.handleApplyToSelectChange} value={this.state.formData.actionApplyTo} />
              } */}
              </FormLayout.Group>
              {this.state.formData.fireMode === "LEAVE" && <div className="Polaris-Labelled__HelpText">
                <span>
                {t("edit.actionAttr.leavePageDesc")}
                </span>
              </div>}

            </FormLayout>
            {
              (parseInt(this.state.formData.comeIn) === 3) && <FormLayout>
                <FormLayout.Group >
                  <ProductSearch searchLabelTxt={t("edit.actionAttr.selectSpecialProduct")} withSku="false" initSearchResultItems={this.state.formData.productDetailPages} searchResultChanged={this.handleProductSearchResultChanged}/>
                </FormLayout.Group>
              </FormLayout>
            }
            {
              (parseInt(this.state.formData.comeIn) === 4) && <FormLayout>
                <FormLayout.Group >
                  <CollectionList initSelectedItems={this.state.formData.collectionPages} onItemChanged={this.handleCollectionChanged} />
                </FormLayout.Group>
              </FormLayout>
            }
            {
              (parseInt(this.state.formData.comeIn) === 2) && <div><div className={styles.fileOnDeviceTitle}>{t("edit.actionAttr.cartTriggerCondition")}</div>
              <div>{this.fireConditions()}</div>
              <CartFireCondition conditions={this.state.formData.cartConditions} onChange={this.handleCartConditionChange} />
              </div>
            }

            <div className={styles.urlPattern}>
              <Checkbox 
                label={t("edit.actionAttr.enableURLRestrict")} 
                checked={this.state.formData.enableURLRestrict} 
                onChange={this.handleEnableURLRestrictChange}
              />

              <TextField value={this.state.formData.urlPattern} 
              onChange={this.handleUrlPatternChange} 
              disabled={!this.state.formData.enableURLRestrict}
              type="text" 
              placeholder={t("edit.actionAttr.urlPatternPlaceholder")}
              onBlur={this.onUrlPatternMoved}
              error={this.state.validation.urlPatternErr ? t("edit.actionAttr.urlPatternError") : ""}
              />
            </div>

            <div className={styles.fileOnDeviceTitle}>{t("edit.actionAttr.triggerOnDevice")}</div>
            <Stack horizontal>
              <RadioButton
                label={t("edit.actionAttr.allDevice")}
                checked={this.state.formData.showInDevice === '0'}
                id="0"
                onChange={this.handleApplyDeviceChange}
              />
              <RadioButton
                label={t("edit.actionAttr.mobile")}
                checked={this.state.formData.showInDevice === '1'}
                id="1"
                onChange={this.handleApplyDeviceChange}
              />
              <RadioButton
                label={t("edit.actionAttr.pc")}
                checked={this.state.formData.showInDevice === '2'}
                id="2"
                onChange={this.handleApplyDeviceChange}
              />
            </Stack>
     

        </Card>
        {
          this.doActionComponents()
        }
        
        <PageActions primaryAction={{content: btnName, disabled: this.state.disableSaveBtn, onAction: this.handleSubmit}}/>
      </Page>
        )
  }
}


export default withTranslation()(Index)
