import request from "../utils/request"

export function addAction(data) {
  return request.post(`api/action/add`, data)
}

export function getActionList(status) {
  if(status === undefined) {
    return request.get(`api/action`)
  } else {
    return request.get(`api/action?status=${status}`)
  }
  
}

export function updateAction(id, data) {
  return request.put(`api/action/${id}`, data)
}

export function deleteAction(id) {
  return request.delete(`api/action/${id}`)
}


export async function activeAction(id) {
  return request.post(`api/action/active/${id}`)
}


export async function disableAction(id) {
  return request.post(`api/action/disable/${id}`)
}