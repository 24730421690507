import React from "react"
import {Page, Layout, Card, List, Stack, Button, Toast, Frame} from '@shopify/polaris';
import { withTranslation, Trans } from 'react-i18next';

const CollectionReorder = ({t}) => {

  return (
    <Frame>
      <Page fullWidth>
        <Layout>
          <Layout.Section>
            <Card title={t("collectionReorder.title")} sectioned>
              <div>
                <p><strong>{t("collectionReorder.what")}</strong></p>
                <p>{t("collectionReorder.whatdesc")}</p>
              </div>
            </Card>
          </Layout.Section>
        </Layout>
      </Page>
    </Frame>
  )
}
export default withTranslation()(CollectionReorder)
