import React from "react" 
// import { Button } from '@shopify/polaris';
import styles from "./recommend_product.module.scss"
import { withTranslation } from 'react-i18next';

const RecommendProductView = ({products, onProductDelete, upsellInfo = {}, t}) => {

  const cartButtonStyle = {
    'color': upsellInfo.addToCartTextColor,
    'backgroundColor': upsellInfo.addToCartBkColor,
    'border': `solid 1px ${upsellInfo.addToCartBkColor}`
  }

  const discountView = (skuItem) => {
    if(skuItem.price > 0 && skuItem.price < skuItem.comparePrice) {
      const off = (((skuItem.comparePrice-skuItem.price)/skuItem.comparePrice) * 100).toFixed(0)
      return <div className={styles.offview}><div>{off}%</div><div>OFF</div></div>
    }
  }

  // console.log(products)
  const productViews = products && products.map((item) => {
    if(item.optionSkus === null || item.optionSkus === undefined) {
      return <div></div>
    }
    const sku = item.optionSkus[0]
    return (
      <div className={styles.item} key={item.platformId}>
        <div className={styles.imgbox}><img src={item.productImageUrl} alt={item.title}/></div>
        <div className={styles.titlebox} title={item.title}>{item.title}</div>
        <div className={styles.pricebox}>
          <span className={styles.price}>{item.moneyFormat}{sku.price}</span>
          { sku.price < sku.comparePrice && <span className={styles.comparePrice}>{item.moneyFormat}{sku.comparePrice}</span>}
        </div>
        {onProductDelete && <span className={styles.delete} onClick={() => { deleteRecommendSku(item.platformId)}}>
        &#xd7;
        </span>}
        <div className={styles.addToCart}>
          <button style={cartButtonStyle}>{upsellInfo.addToCartText}</button>
        </div>
        {
          discountView(sku)
        }
      </div>
    )
  })

  const deleteRecommendSku = (productId) => {
    if(window.confirm(t("upsell.recommendPts.deleteAlert"))) {
      console.log(productId)
      if(onProductDelete) {
        onProductDelete(productId)
      }
    }
  }
  return (
    <>
      <div className={styles.product_list_container}>{ productViews }</div>
    </>
  )
}
export default withTranslation()(RecommendProductView)
