import axios from "axios";
import Loading from "../components/loading";
// import {Redirect} from '@shopify/app-bridge/actions';
import i18n from '../i18n';

const request = axios.create({
    timeout: 1000 * 60 * 2, // request timeout
});

// request interceptor
request.interceptors.request.use((config) => {
        let {
            headers,
        } = config || {};
        const urlParams = new URLSearchParams(window.location.search);
        headers["Shop"] = urlParams.get("shop");
        headers['Content-Type'] = 'application/json';
        Loading.show();
        return config;

        // Loading.show();

        // return getSessionToken(window.app) // requires a Shopify App Bridge instance
        // .then((token) => {
        //     const urlParams = new URLSearchParams(window.location.search);
        //     config.headers["Shop"] = urlParams.get("shop");
        //     config.headers["Authorization"] = `Bearer ${token}`;
        //     return config;
        // });
    },
    (error) => {
        // Do something with request error
        Loading.hide();
        console.log(error); // for debug
        Promise.reject(error);
    }
);

// response interceptor
request.interceptors.response.use(
    (response) => {
        let {
            status,
            data,
            statusText
        } = response || {};
        Loading.hide();
        if (status === 200 || status >= 5000) {
            return Promise.resolve(data);
        } else {
            alert(statusText || "server error");
            return Promise.reject(data);
        }
    },
    (error) => {
        // console.log(error.response)
        Loading.hide();
        console.log("err" + error); // for debug
        if(error.response && error.response.status === 403) {
            alert(i18n.t("httpRequest.authFailed"))
            window.top.location.href = error.response.data
            return 
        }
        error.response && error.response.status === 400 && alert(error.response.data)

        return Promise.reject(error);
    }
);

export default request
